import React, { useState } from 'react';
import CreateAccount from './CreateAccount';

import './Style.scss';
import PaymentDetails from './PaymentDetails';
import Finished from './Finished';
import AuthHeader from '../../components/Header/AuthHeader/AuthHeader';


const Register = () => {


    const [step, setStep] = useState(1);
    const [user, setUser] = useState();

    const prevStep = () => {
        setStep(step - 1);
    }

    const nextStep = () => {
        setStep(step + 1);
    }

    const onlyNumbers = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    const WizardSteps = () => {
        switch (step) {
            case 1:
                return (
                    <CreateAccount onlyNumbers={onlyNumbers} nextStep={nextStep} setUser={setUser} />
                )
            case 2:
                return (
                    <PaymentDetails prevStep={prevStep} nextStep={nextStep} user={user} />
                )

            case 3:
                return (
                    <Finished />
                )
            default:
            // do nothing...
        }
    }

    return (
        <div className="register d-flex align-items-center justify-content-center min-vh-100">
            <AuthHeader showURL={"login"} />
            <WizardSteps />
        </div>
    )
}

export default Register