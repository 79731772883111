import './NotificationDropdown.scss';
import Avatar from 'react-avatar';

const NotificationDropdown = ({ notifications, isNotificationVisible }) => {

    return (
        <div className={`notification-menu ${isNotificationVisible ? 'open' : ''}`}>
            <div className="notification-menu-header">
                <h4>Notifications ({notifications.length})</h4>
            </div>
            <div className="notification-menu-content">
                {notifications.length > 0 ? (
                    notifications.map((notification, i) => (
                        <div className="notification-item">
                            <div className="content">
                                <Avatar size={30} name={'Pradeep Gokul'} round={true} color={'#0c5242'} />
                                <div className="message">Pradeep Gokul just joined as your client</div>
                            </div>
                            <div className="time">
                                3 mins ago
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="notification-empty-container">
                        <p>No Notifications yet!</p>
                    </div>
                )
                }
            </div>
        </div>
    )
}

export default NotificationDropdown 