import { api } from '../middlewares';

const getAll = (id, metric) => {
    return api.get(`/metrics/${id}/${metric}`,);
}

const add = (data) => {
    return api.post(`/metric`, data);
}

const edit = (id, data) => {
    return api.put(`/metric/${id}`, data);
}

const _delete = (id) => {
    return api.delete(`/metric/${id}`);
}


const MetricService = {
    getAll,
    add,
    edit,
    _delete
};

export default MetricService;