import React from 'react'
import WorkoutBuilder from './WorkoutBuilder';

import './WorkoutBuilder.scss';

const WBLayout = ({ day, workoutId, setIsModalOpen }) => {
    return (
        <div className="workout-builder-modal">
            <WorkoutBuilder day={day} workoutId={workoutId} setIsModalOpen={setIsModalOpen} />
        </div>
    )
}

export default WBLayout