import { useEffect, useState, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import { addDoc, collection, query, orderBy, onSnapshot, Timestamp } from 'firebase/firestore';
import { format, isToday } from 'date-fns';
import Avatar from 'react-avatar'

import MessageInput from './MessageInput'
import MessagesShimmer from '../../components/Shimmers/MessagesShimmer';

import { database } from '../../firebase';
import { ClientService } from '../../services';

const Conversations = () => {

    const { id } = useParams();
    const location = useLocation();
    const scrollRef = useRef();

    const [messages, setMessages] = useState([]);

    const [client, setClient] = useState();
    const [from, setFrom] = useState(localStorage.getItem('uid'));
    const [to, setTo] = useState(id);

    useEffect(() => {

        (async () => {
            await ClientService.getById(id).then((res) => {
                console.log(res.data.data);
                setClient(res.data.data);
                setFrom(res.data.data.coach_id)
                setTo(res.data.data.uid);
            }).catch((err) => {
                console.log(err);
            })
        })();

        (async () => {

            const id = from > to ? `${from + to}` : `${to + from}`;

            const collectionRef = collection(database, 'messages', id, 'message');
            const q = query(collectionRef, orderBy('createdAt', 'desc'));

            const unsubscribe = onSnapshot(q, snapshot => {
                console.log('snapshot', snapshot);

                snapshot.forEach((doc) => {
                    console.log(doc.data());
                })

                setMessages(snapshot.docs.map(doc => ({
                    from: doc.data().from,
                    createdAt: doc.data().createdAt.toDate(),
                    text: doc.data().text,
                    to: doc.data().to
                })))
            });

            return () => unsubscribe();
        })();
        // eslint-disable-next-line
    }, [location]);

    const handleSendMsg = async (msg) => {
        console.log(from, to);

        const id = from > to ? `${from + to}` : `${to + from}`;

        await addDoc(collection(database, 'messages', id, 'message'), {
            text: msg,
            from: from,
            to: to,
            createdAt: Timestamp.fromDate(new Date())
        });
    }

    const formatDate = (date) => {
        if (isToday(date)) {
            return 'Today';
        } else {
            return format(date, 'EEE, dd MMM');
        }
    }

    const messageGroups = {};
    messages.forEach(message => {
        const date = formatDate(message.createdAt);
        if (!messageGroups[date]) {
            messageGroups[date] = [];
        }
        messageGroups[date].push(message);
    });

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);



    return (
        <div className="message-conversation">
            <div className="message-conversation-container">
                <div className="message-conversation-header">
                    <Avatar size="40" name={client?.fullname} round={true} color={'#0c5242'} />
                    {/* <img className="avatar rounded-circle mr-3" src={currentChat.avatar} alt={`avatar-${currentChat._id.substr(currentChat._id.length - 5)}`} /> */}
                    <span className="user-name">{client?.fullname}</span>
                </div>
                <div className="message-conversation-history">
                    {messages ?
                        <div className="message-conversation-history-frame">
                            {/* {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`message ${message.from === client.uid ? "recieved" : "sended"}`}>
                                    <div className="message-inner ">
                                        <div className="message-body">
                                            <div className="message-content">
                                                <div className="message-text">
                                                    <p>{message.text}</p>
                                                </div>
                                                <div className="message-time">
                                                    <span className="extra-small">{format(message.createdAt, 'HH:mm')}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))} */}

                            {Object.entries(messageGroups).map(([date, messages]) => (
                                <div key={date}>
                                    <div className="messaged-on">
                                        <span>{date}</span>
                                    </div>
                                    <div className="message-group">
                                        {messages.map((message, index) => (
                                            <div
                                                key={index}
                                                className={`message ${message.from === client.uid ? "recieved" : "sended"}`}>
                                                <div className="message-inner ">
                                                    <div className="message-body">
                                                        <div className="message-content">
                                                            <div className="message-text">
                                                                <p>{message.text}</p>
                                                            </div>
                                                            <div className="message-time">
                                                                <span className="extra-small">{format(message.createdAt, 'HH:mm')}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <MessagesShimmer />
                    }
                </div>
                <div className="message-conversation-input">
                    <MessageInput handleSendMsg={handleSendMsg} />
                </div>
            </div>
        </div>
    )
}

export default Conversations