import { Outlet } from 'react-router-dom';
import { AppHeader } from '../../components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';

const AppLayout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onIdTokenChanged((user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    localStorage.setItem('access_token', token);
                });
            } else {
                localStorage.clear();
                signOut(auth);
                navigate('/login', { replace: true });
            }
        });

        return () => unsubscribe();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="app-root">
            <div className="app-header">
                <AppHeader />
            </div>
            <div className="app-content">
                <Outlet />
            </div>
        </div>
    )
}

export default AppLayout