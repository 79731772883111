import { useOutlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppLoader } from '../../components';


const Root = () => {
  const outlet = useOutlet();

  return (
    <div className="global-container">
      {outlet}
      <ToastContainer theme="dark" hideProgressBar autoClose={1000} />
      <AppLoader />
    </div>
  )
}

export default Root