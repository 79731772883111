import { api } from '../middlewares';


const getById = (id) => {
    return api.get(`/coach/${id}`);
}

const getClients = (id) => {
    return api.get(`/coach/${id}/clients`);
}

const sendInvite = (data) => {
    return api.post(`/coach/invite`, data);
}

const updateProfile = (id, data) => {
    return api.put(`coach/${id}`, data);
}


const CoachService = {
    getById,
    getClients,
    sendInvite,
    updateProfile
}

export default CoachService