import { api } from '../middlewares';

const getAll = (id) => {
    return api.get(`/notifications/${id}`);
}


const getNotificationSettings = (id) => {
    return api.get(`/settings/notification/${id}`);
}

const updateNotificationSettings = (id, data) => {
    return api.put(`/settings/notification/${id}`, data);
}


const NotificationService = {
    getAll,
    getNotificationSettings,
    updateNotificationSettings
};

export default NotificationService;