import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { BarChart } from '../../components/Icons'
import Notes from '../../components/Notes/Notes';
import { ClientService, MetricService } from '../../services';
import BodyFatMeticChart from '../../components/Charts/BodyFatMeticChart';
import WeightMetricChart from '../../components/Charts/WeightMetricChart';


const ClientOverview = () => {

    const params = useParams();
    const id = params.id;

    const [client, setClient] = useState();
    const [bodyFatData, setBodyFatData] = useState([]);
    const [weightData, setWeightData] = useState([]);


    useEffect(() => {
        (async () => {
            await ClientService.getById(id).then((res) => {
                console.log(res.data.data);
                setClient(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
        })();

        (async () => {
            await MetricService.getAll(id, 'bodyfat').then((res) => {
                console.log(res.data.data)
                setBodyFatData(res.data.data)
            }).catch((err) => {
                console.log(err);
            })
        })();

        (async () => {
            await MetricService.getAll(id, 'weight').then((res) => {
                console.log(res.data.data)
                setWeightData(res.data.data)
            }).catch((err) => {
                console.log(err);
            })
        })();
    }, [id])

    return (
        <div className="client-overview">
            {client ?
                <div className="client-overview-wrapper">
                    <div className="row justify-content-center mb-5">
                        <div className="col">
                            <h1 className="header-title">
                                Overview
                            </h1>
                        </div>
                    </div>
                    <div className="client-overview-wrapper">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="col">
                                    <div className="app-card">
                                        <div className="app-card-header">
                                            <h3 className="app-card-title">Training</h3>
                                        </div>
                                        <div className="app-card-content training-overview">
                                            <div className="row">
                                                <div className="col training-overview-metrics">
                                                    <div className="metric-name">Workout Assigned</div>
                                                    <div className="metric-value">{client.exercise_metrics.assigned}</div>
                                                    <div className="metric-info">Total Workouts Assigned</div>
                                                </div>
                                                <div className="col training-overview-metrics">
                                                    <div className="metric-name">Workout Completed</div>
                                                    <div className="metric-value">{client.exercise_metrics.completed}</div>
                                                    <div className="metric-info">Total Workouts Completed</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="app-card">
                                        <div className="app-card-header">
                                            <h3 className="app-card-title">Body Metrics</h3>
                                        </div>
                                        <div className="app-card-content body-metric-overview">
                                            <div className="row">
                                                <div className="col body-metric">
                                                    <div className="body-metric-chart">
                                                        <div className="metric-chart-header">
                                                            <h4>Body Fat</h4>
                                                        </div>
                                                        <div className="metric-chart-content">
                                                            {bodyFatData && bodyFatData.length === 0 ? (
                                                                <div className="empty-metric-chart">
                                                                    <BarChart />
                                                                    <p>No Data</p>
                                                                </div>
                                                            ) : (
                                                                <BodyFatMeticChart data={bodyFatData} />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col body-metric">
                                                    <div className="body-metric-chart">
                                                        <div className="metric-chart-header">
                                                            <h4>Weight</h4>
                                                        </div>
                                                        <div className="metric-chart-content">
                                                            {weightData && weightData.length === 0 ? (
                                                                <div className="empty-metric-chart">
                                                                    <BarChart />
                                                                    <p>No Data</p>
                                                                </div>
                                                            ) : (
                                                                <WeightMetricChart data={weightData} />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <Notes id={id} />
                            </div>
                        </div>
                    </div>
                </div>
                :
                "Loading..."
            }
        </div>
    )
}

export default ClientOverview