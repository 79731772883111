import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify';

import { LibraryService, WorkoutService } from '../../services';
import { ArrowLeft, Close, CloseSquare, Edit, EmptyExercises, Move, Save } from '../Icons';
import ExercisesList from './ExercisesList';
import ExerciseFilterDropdown from '../Dropdowns/ExerciseFilterDropdown/ExerciseFilterDropdown';
import { format } from 'date-fns';

import Modal from 'react-modal';
import PreviewExercise from '../Modals/PreviewExercise/PreviewExercise';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    transform: 'translate(-50%, -50%)',
  },
};

const WorkoutBuilder = ({ day, workoutId, setIsModalOpen }) => {

  const filterRef = useRef(null);

  const [selectedExercises, setSelectedExercises] = useState([]);
  const [exercises, setExercises] = useState([]);

  // const [workout, setWorkout] = useState();

  const [workoutName, setWorkoutName] = useState('Rest Day');
  const [isEditingName, setIsEditingName] = useState(false);

  const [isFilterVisible, setFilterVisibility] = useState(false);
  const [isTitleError, setIsTitleError] = useState(false);
  const [isExercisesError, setIsExercisesError] = useState(false);



  const [exercisePreviewModal, setExercisePreviewModal] = useState(false);
  const [exercisePreview, setExercisePreview] = useState({});


  useEffect(() => {

    let date = format(day, 'dd-MM-yyyy');

    console.log(date);

    (async () => {
      await WorkoutService.getCalendar(localStorage.getItem('PROGRAMME_ID')).then((res) => {
        let workout = (res.data.data.workouts || []).filter(o => o.date === date);
        if (workout.length) {
          setWorkoutName(workout[0].name);
          setSelectedExercises(workout[0].exercises);
        }

      }).catch((err) => {
        console.log(err);
      })

      await LibraryService.getExercises().then((res) => {
        console.log(res.data.data);
        setExercises(res.data.data);
      }).catch((err) => {
        console.log(err);
      });
    })();


    const handleOutsideClick = (e) => {
      if (filterRef.current && !filterRef.current.contains(e.target) && e.target !== document.querySelector('.filter-toggle')) {
        setFilterVisibility(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };

  }, [day])


  // Empty Message Container

  const EmptyContainerMessage = () => {
    return (
      <div className="d-flex justify-content-center align-items-center flex-column empty-container">
        {/* <h2>Circuit is empty</h2> */}
        <EmptyExercises />
        <p>You can drag and drop the exercises from right to add</p>
        {isExercisesError && <div className="wb-error-container">
          <div className="wb-error">Please add at minimum of 1 exercise</div>
        </div>}
      </div>
    )
  }

  // Handle Drag N Drop
  const handleDrop = (e) => {
    e.preventDefault();
    const exerciseId = e.dataTransfer.getData("exerciseId");
    const exercise = exercises.find((ex) => ex.id.toString() === exerciseId);
    if (exercise && !selectedExercises.some((ex) => ex.id === exercise.id)) {
      const newExercise = {
        id: exercise.id,
        name: exercise.name,
        body_segments: exercise.body_segments,
        sets: 3,
        reps: 10,
        load: 100,
        rest: 60,
        video_url: exercise.video_url
      };
      setSelectedExercises([...selectedExercises, newExercise]);
      setIsExercisesError(false);
    }
  };

  const handleRemove = (exerciseId) => {
    const updatedExercises = selectedExercises.filter(
      (ex) => ex.id.toString() !== exerciseId
    );
    setSelectedExercises(updatedExercises);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  const handleDropReorder = (e, targetIndex) => {
    e.preventDefault();
    const sourceIndex = parseInt(e.dataTransfer.getData("index"));
    const updatedExercises = [...selectedExercises];
    const [movedExercise] = updatedExercises.splice(sourceIndex, 1);
    updatedExercises.splice(targetIndex, 0, movedExercise);
    setSelectedExercises(updatedExercises);
  };

  const handleExerciseChange = (exerciseId, field, value) => {
    const updatedExercises = selectedExercises.map((exercise) => {
      if (exercise.id === exerciseId) {
        return { ...exercise, [field]: value };
      }
      return exercise;
    });
    setSelectedExercises(updatedExercises);
  };


  // Workout Name Change

  const handleNameClick = () => {
    setIsEditingName(true);
    setIsTitleError(false);
  };

  const handleNameChange = (e) => {
    setWorkoutName(e.target.value);
  };

  const handleNameBlur = () => {
    setIsEditingName(false);
  };

  // Workout Overview

  const calculateEstimatedDuration = () => {
    const totalTimeInSeconds = selectedExercises.reduce(
      (total, exercise) => total + exercise.sets * exercise.reps * exercise.rest,
      0
    );

    return totalTimeInSeconds;
  };

  const calculateEstimatedLoad = () => {
    const totalLoad = selectedExercises.reduce((total, exercise) => total + exercise.load * exercise.sets * exercise.reps, 0);

    return `${totalLoad} lbs`;
  };

  const calculateEstimatedIntensity = () => {
    if (selectedExercises.length === 0) {
      return "-";
    }
    const totalIntensity = selectedExercises.reduce(
      (total, exercise) => total + exercise.load * exercise.sets * exercise.reps,
      0
    );

    const averageIntensity = totalIntensity / selectedExercises.length;

    if (averageIntensity <= 50) {
      return "Minimal";
    } else if (averageIntensity <= 100) {
      return "Moderate";
    } else {
      return "Advanced";
    }
  };


  // Exercise Preview
  const handleExercisePreview = (exercise) => {
    setExercisePreviewModal(true);
    setExercisePreview(exercise);
  }

  const closeExercisePreview = () => {
    setExercisePreviewModal(false);
    setExercisePreview({});
  }


  // Add Exercise 

  const handleAddExercise = (exerciseToAdd) => {

    if (!selectedExercises.some(exercise => exercise.id === exerciseToAdd.id)) {
      const newExercise = {
        id: exerciseToAdd.id,
        name: exerciseToAdd.name,
        body_segments: exerciseToAdd.body_segments,
        sets: 3,
        reps: 10,
        load: 100,
        rest: 60,
      };
      setSelectedExercises([...selectedExercises, newExercise]);
    }
  };

  // Save Workout

  const handleSave = async () => {

    console.log(workoutId);

    if (workoutName === "Rest Day") {
      setIsTitleError(true);
    }

    if (selectedExercises.length === 0) {
      setIsExercisesError(true);
    }

    if (selectedExercises.length !== 0 && workoutName !== "Rest Day") {
      const workoutData = {
        pid: localStorage.getItem('PROGRAMME_ID'),
        date: format(day, 'dd-MM-yyyy'),
        name: workoutName,
        estDuration: calculateEstimatedDuration(),
        estLoad: calculateEstimatedLoad(),
        intensity: calculateEstimatedIntensity(),
        exercises: selectedExercises.map((exercise) => ({
          id: exercise.id,
          name: exercise.name,
          sets: exercise.sets || 1,
          reps: exercise.reps || 12,
          load: exercise.load || 100,
          rest: exercise.rest || 0,
          video_url: exercise.video_url || ''
        }))
      }

      await WorkoutService.addWorkout(workoutData).then((res) => {
        console.log(res.data.data);
        setIsModalOpen(false);
        toast.success('Workout Saved Successfully');
      }).catch((err) => {
        console.log(err);
      })

      // console.log('Saved Workout Data:', workoutData);
    }

  }


  return (
    <div className="wb-layout">
      <ExercisesList handleExercisePreview={handleExercisePreview} handleAddExercise={handleAddExercise} setFilterVisibility={setFilterVisibility} />
      <div className="workout-builder wb">
        {/* Header */}

        <div className="wb-header">
          <div className="wb-back">
            <button onClick={() => setIsModalOpen(false)}>
              <ArrowLeft />
            </button>
          </div>
          <div className="wb-title">
            {isEditingName ? (
              <div className="edit-input-title">
                <input
                  type="text"
                  className="form-control"
                  value={workoutName}
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                  autoFocus
                />
              </div>

            ) : (
              <div className="edit-title" onClick={() => handleNameClick()}>
                <h4>{workoutName}</h4>
                <span onClick={() => handleNameClick()}> <Edit /></span>
              </div>
            )}

            {isTitleError && <div className="wb-error">Please add a workout name</div>}
          </div>
        </div>
        {/* Overview */}
        <div className="wb-overview">
          <div className="wb-info">
            <div className="d-flex">
              <div className="col">
                <small>Est Duration</small>
                <p>{`${Math.floor(calculateEstimatedDuration() / 60)} mins ${calculateEstimatedDuration() % 60} sec`}</p>
              </div>
              <div className="col">
                <small>Weight Load</small>
                <p>{calculateEstimatedLoad()}</p>
              </div>
              <div className="col">
                <small>Intensity</small>
                <p>{calculateEstimatedIntensity()}</p>
              </div>
            </div>
          </div>
          <div className="wb-chart">

          </div>
        </div>
        {/* Main */}
        <div className="wb-main">
          <div className="wb-main-wrapper workout-area"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <div className="card">
              <div className="card-body-title">Exercises</div>
              <div className="card-body">
                {selectedExercises.length > 0 ?
                  <div className="exercises-group">
                    {selectedExercises.map((exercise, index) => (
                      <div className="selected-exercise"
                        key={exercise.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDrop={(e) => handleDropReorder(e, index)}
                      >
                        <div className="row align-items-center">
                          <div className="col">
                            <h4 className="exercise-name">{exercise.name}</h4>
                          </div>

                          <div className="col-auto">
                            <input
                              type="number"
                              value={exercise.sets || '1'}
                              onChange={(e) =>
                                handleExerciseChange(exercise.id, "sets", parseInt(e.target.value))
                              }
                            />
                            <span> sets</span>
                          </div>
                          <div className="col-auto">
                            <input
                              type="number"
                              value={exercise.reps || '12'}
                              onChange={(e) =>
                                handleExerciseChange(exercise.id, "reps", parseInt(e.target.value))
                              }
                            />
                            <span> reps</span>
                          </div>

                          <div className="col-auto">
                            <input
                              type="number"
                              value={exercise.load || '20'}
                              onChange={(e) =>
                                handleExerciseChange(exercise.id, "load", parseInt(e.target.value))
                              }
                            />
                            <span> load</span>
                          </div>

                          <div className="col-auto">
                            <input
                              type="number"
                              value={exercise.rest || '60'}
                              onChange={(e) =>
                                handleExerciseChange(exercise.id, "rest", parseInt(e.target.value))
                              }
                            />
                            <span> rest</span>
                          </div>
                          <div className="col-auto">
                            <button className="exercise-icon exercise-move"><Move /></button>
                          </div>
                          <div className="col-auto">
                            <button className="exercise-icon exercise-remove" onClick={() => handleRemove(exercise.id.toString())}><CloseSquare /></button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  : <EmptyContainerMessage />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="wb-footer">
        <div className="wb-footer-wrapper">
          <button>
            <div className="button-inner" onClick={() => setIsModalOpen(false)}>
              <Close />
              Cancel
            </div>
          </button>
          <button onClick={() => handleSave()}>
            <div className="button-inner">
              <Save />
              Save & Close
            </div>
          </button>
        </div>
      </div>
      <div ref={filterRef}>
        <ExerciseFilterDropdown isFilterVisible={isFilterVisible} setFilterVisibility={setFilterVisibility} />
      </div>
      <Modal
        isOpen={exercisePreviewModal}
        onRequestClose={closeExercisePreview}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <PreviewExercise closeExercisePreview={closeExercisePreview} exercise={exercisePreview} />
      </Modal>
    </div>
  )
}

export default WorkoutBuilder