import { Outlet } from 'react-router-dom';

const AuthLayout = () => {

    return (
        <div className="app-locked">
            <div className="auth-page">
                <Outlet />
                <footer className="auth-footer">
                    <div className="copyright-text">© {new Date().getFullYear()} Prime Coach. All rights reserved.</div>
                </footer>
            </div>
        </div>
    )
}

export default AuthLayout