import { useEffect, useState, useRef } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Avatar from 'react-avatar';
import { signOut } from 'firebase/auth';

import { auth } from '../../../firebase';
// import { Notification, User, Feedback, Logout, Logo } from '../../Icons';
import { Notification, User, Logout, Logo } from '../../Icons';

import CoachService from '../../../services/CoachService';
import NotificationDropdown from '../../Dropdowns/NotificationDropdown/NotificationDropdown';

import './AppHeader.scss';
import NotificationService from '../../../services/NotificationService';





const AppHeader = () => {

  const navigate = useNavigate();
  const notificationRef = useRef(null);


  const [user, setUser] = useState();
  const [isNotificationVisible, setNotificationVisibility] = useState(false);
  const [notifications, setNotifications] = useState([]);


  const logoutCurrent = async () => {
    await signOut(auth);
    await localStorage.clear();
    navigate('/login', { replace: true });
  }

  useEffect(() => {
    (async () => {
      CoachService.getById(localStorage.getItem('uid')).then((res) => {
        if (res.data.data) {
          setUser(res.data.data);
        }
      }).catch((err) => {
        console.log(err);
      })

      await NotificationService.getAll(localStorage.getItem('uid')).then((res) => {
        setNotifications(res.data.data.notifications);
      }).catch((err) => {
        console.log(err);
      })

    })();


    const handleOutsideClick = (e) => {
      if (notificationRef.current && !notificationRef.current.contains(e.target) && e.target !== document.querySelector('.notification-toggle')) {
        setNotificationVisibility(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };

  }, [])

  return (
    <div className="navbar">
      <div className="left-menu">
        <NavLink to="/clients">
          <Logo />
        </NavLink>
      </div>
      <div className="center-menu">
        <div className="navbar-menu">
          <NavLink to="/clients" className="menu-item">Clients</NavLink>
          <NavLink to="/messages" className="menu-item">Messages</NavLink>
          <NavLink to="/library" className="menu-item">Library</NavLink>
        </div>
      </div>
      <div className="right-menu">
        <div className="nav-items d-flex flex-row align-center">
          <div className={`notification${notifications.length > 0 ? ' unread' : ''}`}>
            <div className="notification-toggle" onClick={() => setNotificationVisibility(true)}>
              <Notification />
            </div>
            <div ref={notificationRef}>
              <NotificationDropdown notifications={notifications} isNotificationVisible={isNotificationVisible} />
            </div>
          </div>
          <div className="user-section dropdown">
            <div className="avatar avatar-l dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              {user?.avatar ? (
                <Avatar src={user?.avatar} size="40" round={true} color={'#0c5242'} />
              ) : (
                <Avatar name={user?.fullname} size="40" round={true} color={'#0c5242'} />
              )}
            </div>
            <ul className="dropdown-menu">
              <li className="dm-user-details">
                <p>{user?.fullname}</p>
                <p>{user?.email}</p>
              </li>
              <hr className="my-2" />
              <li>
                <Link className="dropdown-item" to={`/account`}>
                  <span><User /></span>
                  <span>Account</span>
                </Link>
              </li>
              {/* <li>
                <Link className="dropdown-item" to={`/feedback`}>
                  <span><Feedback /></span>
                  <span>Feedback</span>
                </Link>
              </li> */}
              <li>
                <button className="dropdown-item" onClick={() => logoutCurrent()}>
                  <span><Logout /></span>
                  <span>Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppHeader