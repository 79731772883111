import React from 'react';

import './DownloadApp.scss';
import AuthHeader from '../../components/Header/AuthHeader/AuthHeader';
import { AppStore, PlayStore } from '../../components/Icons';

const DownloadApp = () => {

    const onClick = (e) => {
        e.preventDefault()
        console.log('onclick..')
    }

    return (
        <div className="downloadApp d-flex align-items-center min-vh-100">
            <AuthHeader />

            <div className="container">
                <div className="justify-content-center row">
                    <div className="my-5 col-xl-4 col-md-5 col-12 auth-bg">
                        <div className="download-info">
                            <h3>Download</h3>
                            <p>Available for iOS and Android</p>
                        </div>
                        <div className="download-icons">
                            <a href onClick={() => onClick()} className="store-icons">
                                <AppStore />
                            </a>
                            <a href onClick={() => onClick()} className="store-icons">
                                <PlayStore />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DownloadApp