import { api } from '../middlewares';

const createNewOrder = () => {
    return api.post(`/billing/payment/orders`);
}

const checkTransaction = (data) => {
    return api.post(`/billing/payment/success`, data);
}


const BillingService = {
    createNewOrder,
    checkTransaction
}

export default BillingService