import { Line } from 'react-chartjs-2';

const WeightMetricChart = ({ data }) => {


    function getGradient(chart) {

        const { ctx } = chart;

        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, "rgba(57, 168, 137, 0.5)");
        gradient.addColorStop(0.5, "rgba(57, 168, 137, 0.25)");
        gradient.addColorStop(1, "rgba(57, 168, 137, 0)");
        return gradient;
    }

    const chartData = () => {
        return {
            labels: data.map((entry) => entry.date),
            datasets: [
                {
                    label: 'Weight',
                    data: data.map((entry) => entry.value),
                    borderColor: 'rgba(57, 168, 137, 1)',
                    fill: true,
                    pointBackgroundColor: 'white',
                    borderWidth: 3,
                    tension: 0.4,
                    backgroundColor: (context) => {
                        const chart = context.chart;
                        const { chartArea } = chart;

                        if (!chartArea) {
                            return null
                        }

                        if (context.datasetIndex === 0) {
                            return getGradient(chart);
                        } else {
                            return 'black'
                        }
                    },
                },
            ],
        }
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        animation: {
            easing: 'easeInOutQuad',
            duration: 520
        },
        scales: {
            x: {
                // display: false,
                ticks: {
                    display: false
                },
                gridLines: {
                    color: 'red',
                    lineWidth: 1
                },
            },
            y: {
                ticks: {
                    display: false
                },
                gridLines: {
                    color: 'red',
                    lineWidth: 1
                },
                min: 2,
                max: 100
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    }

    return (
        <div className="metric-chart">
            {data && <Line options={chartOptions} data={chartData()} />}
        </div>
    )
}

export default WeightMetricChart