import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Error.scss';

const Error = () => {

    const navigation = useNavigate();

    const goBackHome = () => {
        navigation('/');
    }

    return (
        <div className="error-page d-flex align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 col-xl-12 my-5">
                        <div className="text-center">
                            <h6 className="text-uppercase mb-4">
                                404 Not Found
                            </h6>
                            <h3 className="mb-3">
                                Sorry, that page doesn’t exist!
                            </h3>
                            <p className="text-muted mb-4">
                                Looks like you ended up here by accident?
                            </p>
                            <button onClick={() => goBackHome()} className="pcx-btn pcx-btn-primary">Return Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error