import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MessageLoader } from '../../components';
import Avatar from 'react-avatar';
import { Search } from '../../components/Icons';

const MessageList = ({ clients }) => {
  const [searchValue, setSearchValue] = useState('');


  return (
    <div className="message-list">
      <div className="message-list-heading">
        <h3>Messages</h3>
      </div>
      <div className="message-list-search">
        <Search />
        <input type="search" name="searchUsers" value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder="Search Clients" />
      </div>
      <div className="message-list-main">
        <div className="user-list">
          {!clients && <MessageLoader />}
          {clients && clients.map((client, index) => (
            <NavLink to={`chat/${client.id}`} className="client" key={index}>
              <Avatar size="48" name={client.fullname} round={true} color={'#0c5242'} />
              <span className="user-name">{client.fullname}</span>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MessageList