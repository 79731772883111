import axios from 'axios';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-type": "application/json",
    }
});

axiosInstance.interceptors.request.use((config) => {
    let token = localStorage.getItem('access_token');
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
})

export default axiosInstance