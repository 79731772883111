import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const MessagesShimmer = () => {
    return (
        <div className="messages-loader">
            <Skeleton height={30} />
        </div>
    )
}

export default MessagesShimmer