import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { Spinner, Close } from '../../Icons'

import './PreviewExercise.scss';

const PreviewExercise = ({ closeExercisePreview, exercise }) => {

    const [playerLoading, setPlayerLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setPlayerLoading(false);
        }, 3000);
    }, [])

    return (
        <div className="pcx-modal view-exercise-modal">
            <div className="pcx-modal-content">
                <div className="close-btn">
                    <Close onClick={() => closeExercisePreview()} />
                </div>
                <div className="row">
                    <div className="col">

                        {exercise.video_url === undefined ?
                            <div className="video-unavailable">
                                <span>Video Unavailable</span>
                            </div>
                            :
                            <div className="video-section">
                                {playerLoading ?
                                    <Spinner />
                                    :
                                    <ReactPlayer
                                        muted
                                        loop={true}
                                        playing={true}
                                        url={exercise.video_url}
                                    />
                                }
                            </div>
                        }


                    </div>
                    <div className="col">
                        <h4>{exercise.movement_name}</h4>
                        <div className="row mb-4 exercise-segments">
                            <div className="col-auto">
                                <h6>Body Segments</h6>
                                {exercise.body_segment && exercise.body_segment.map((segment) => (
                                    <p key={segment.id}>{segment.name}</p>
                                ))}                            </div>
                            <div className="col-auto">
                                <h6>Programme Types</h6>
                                {exercise.programme_types && exercise.programme_types.map((programme) => (
                                    <p key={programme.id}>{programme.name}</p>
                                ))}
                            </div>
                            <div className="col-auto">
                                <h6>Difficulty</h6>
                                <p>{exercise.difficulty === "1" ? "Beginner" : exercise.difficulty === "2" ? "Moderate" : "Advanced"}</p>
                            </div>
                        </div>
                        <p>{exercise.description && exercise.description[0].children[0].text}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewExercise