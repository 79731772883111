import { api } from '../middlewares';


const getEntries = () => {
    return api.get(`/library`);
}

const getEntry = (id) => {
    return api.get(`/library/${id}`);
}


const getExercises = () => {
    return api.get(`/library/exercises`);
}

const getExerciseById = (id) => {
    return api.get(`/library/exercises/${id}`);
}


const LibraryService = {
    getEntries,
    getEntry,
    getExercises,
    getExerciseById
};

export default LibraryService;