import { useEffect, useState } from 'react';
import { CoachService } from '../../services'
import { Outlet } from 'react-router-dom';

import MessageList from './MessageList';

import './Messages.scss'

const Messages = () => {

    const [clients, setClients] = useState([]);

    useEffect(() => {
        (async () => {
            await CoachService.getClients(localStorage.getItem('uid')).then((res) => {
                setClients(res.data.data);
            }).catch((err) => {
                console.log(err)
            })
        })();
    }, []);


    return (
        <div className="messages app-main">
            <div className="container">
                <div className="section-content">
                    <div className="message-frame">
                        <div className="message-panel">
                            <MessageList clients={clients} />
                        </div>
                        <div className="message-main">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Messages