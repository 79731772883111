import { addDays, format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ArrowRightLine } from '../../components/Icons';
import { BillingService, UserService } from '../../services';

import logo from '../../assets/images/logo.svg';


const PaymentDetails = ({ prevStep, nextStep, user }) => {

    const todayDate = new Date();
    const nextBillingDate = addDays(todayDate, 30);
    // const [order, setOrder] = useState();

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const iniatePayment = async () => {

        await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        const result = await BillingService.createNewOrder();
        console.log(result);

        const { amount, id: order_id, currency } = result.data.data;

        console.log('Amount', amount);
        console.log('Order ID', order_id);
        console.log('Currency', currency);

        console.log(process.env.REACT_APP_RZP_KEY);

        const options = {
            key: process.env.REACT_APP_RZP_KEY,
            amount: amount.toString(),
            currency: currency,
            name: "Namma Fit Pvt Ltd.",
            description: "Test Transaction",
            image: { logo },
            order_id: order_id,
            handler: async function (response) {
                console.log('Handler Response', response)
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };

                await BillingService.checkTransaction(data).then((res) => {
                    console.log(res);
                    if (res.data) {
                        user["role"] = "coach";
                        UserService.register(user).then((res) => {
                            console.log(res);
                            if (res) {
                                nextStep();
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },
            prefill: {
                name: user.firstname + ' ' + user.lastname,
                email: user.email,
                contact: user.mobilenumber,
            },
            notes: {
                address: "Prime Coach",
            },
            theme: {
                color: "#61dafb",
            },
        };


        const paymentObject = new window.Razorpay(options);
        paymentObject.open();

    }

    return (
        <div className="mt-5 payment-details register">
            <div className="container container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                                <h6 className="mb-4 text-uppercase text-muted">Step 2 of 3</h6>
                                <h1 className="mb-5">Subscription Details</h1>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-2">
                            <div className="col-12 col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="card-header-title text-uppercase text-center">Your Plan</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="mb-0">
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                                    <small>Today you pay</small>
                                                    <small>₹11,999.00 <span className="h6 text-center text-muted mb-5"> /mo</span></small>
                                                </li>
                                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                                    <div>
                                                        <small>Next Billing on <span>{format(nextBillingDate, 'dd MMM yyyy')}</span></small>
                                                        <br />
                                                        <small className="text-muted">Cancel anytime. T&C apply.</small>
                                                    </div>
                                                    <small>₹11,999.00 <span className="h6 text-center text-muted mb-5"> /mo</span> </small>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="membership-checkpoint mb-4 px-2">
                                    <p>
                                        By signing up you agree that your subscription starts immediately and that you
                                        have read and agree to our <Link to={'/terms'}>Terms of Use</Link>, and have read and understood
                                        our <Link to={'/privacy'}>Privacy Policy and Cookie</Link> Notice. Your subscription continues automatically until
                                        you cancel. You can cancel at any time to the end of your applicable subscription
                                        period by selecting 'Cancel Subscription' in My Account.
                                    </p>
                                </div>
                                <div className="payment-action-trigger d-flex justify-content-center">
                                    <button className="pcx-btn pcx-btn-primary pcx-btn-ico" onClick={() => iniatePayment()}>
                                        Start Training Now
                                        <ArrowRightLine />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentDetails