import { useState } from "react"
import HTTPCommon from "../middlewares/HTTPCommon";


const useMutation = ({ url, method = "POST" }) => {
    const [state, setState] = useState({
        isLoading: false,
        error: ''
    });

    const fn = async (data) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }))

        HTTPCommon({ url, method, headers: { 'Content-Type': 'multipart/form-data' }, data }).then(() => {
            setState({ isLoading: false, error: '' })
        }).catch((err) => {
            setState({ isLoading: false, error: err })
        })
    }


    return { mutate: fn, ...state };
}

export default useMutation;