import React, { useEffect, useState } from 'react'
import { Close } from '../../Icons'

import './WorkoutHistory.scss';
import { format } from 'date-fns';
import { WorkoutService } from '../../../services';

const WorkoutHistory = ({ day, setIsCompletedModalOpen }) => {

    const [workout, setWorkout] = useState();

    useEffect(() => {
        let date = format(day, 'dd-MM-yyyy');

        (async () => {
            await WorkoutService.getCalendar(localStorage.getItem('PROGRAMME_ID')).then((res) => {
                let workout = (res.data.data.workouts || []).filter(o => o.date === date);
                if (workout.length) {
                    setWorkout(workout[0]);
                }
            }).catch((err) => {
                console.log(err);
            })
        })();

        // eslint-disable-next-line
    }, []);


    // Need to be in utils class

    function secondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }


    return (
        <div className="pcx-modal workout-completed-modal">
            <div className="pcx-modal-header">
                {/* <h2>{workout?.name}</h2> */}
                <h2>Workout</h2>
                <Close onClick={() => setIsCompletedModalOpen(false)} />
            </div>
            <div className="pcx-modal-content">
                <div className="workout-completed-modal-content">


                    <div className="workout-history-title">
                        <span>{workout?.date}</span>
                        <br />
                        <span>Workout Name</span>
                        <h3>{workout?.name}</h3>
                    </div>

                    <div className="workout-history-info">
                        <div className="left-col-content">
                            <span>Est. Duration</span>
                            {workout?.estDuration ? secondsToMinutes(workout?.estDuration) : '00:00'}
                        </div>
                        <div className="right-col-content">
                            <span>Load</span>
                            {workout?.estLoad}
                        </div>
                    </div>
                    <div className="workout-history-update">
                        <span>Total Duration</span>
                        {workout?.time_spent ? secondsToMinutes(workout?.time_spent) : '00:00'}
                    </div>


                    <div className="workout-list mt-4">
                        <h3>Workout Details</h3>
                        <hr />
                        {workout?.exercises?.map((exercise, index) => (
                            <div className="workout-completed-modal-content-item" key={index}>
                                <div className="workout-history-content-item-col-left">
                                    {exercise.name}
                                </div>
                                <div className="workout-history-content-item-col-right">
                                    <div className="workout-history-content-item-list finished-sets">
                                        <span>Sets</span>
                                        {exercise.finishedLoad && exercise.finishedLoad.length ? 
                                        
                                        exercise?.finishedLoad.map((load, index) => (
                                            <div key={index} className="finished-load">
                                                Set {index + 1}
                                            </div>
                                        ))
                                        :
                                        `Set 1`
                                    }
                                    </div>
                                    <div className="workout-history-content-item-list finished-sets">
                                        <span>Weight</span>
                                        {exercise.finishedLoad && exercise.finishedLoad.length ?

                                            exercise.finishedLoad.map((load, index) => (
                                                <div key={index} className="finished-load">
                                                    {load} kg
                                                </div>
                                            ))

                                            : `0 kg`}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkoutHistory