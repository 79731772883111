import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import './ClientsLoader.scss';

const ClientLoader = () => {

    const numberOfRows = 3;
    const renderedRows = [...Array(numberOfRows)].map((e, i) => (
        <div key={i}>
            <div className="row">
                <Skeleton height={'90px'} />
            </div>
        </div>
    ));

    return (
        <div className="client-table-loader">
            <div className="section-header">
                <div className="section-title">
                    <Skeleton width={'200px'} height={'25px'} />
                </div>
                <div className="section-action">
                    <Skeleton width={'150px'} height={'40px'} />
                </div>
            </div>
            <div className="section-content">
                {renderedRows}
            </div>
        </div>
    )
}

export default ClientLoader