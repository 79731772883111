import { Formik } from 'formik';
import { UserService } from '../../services';


const ChangePassword = () => {

    const initialValues = {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    };

    const validate = (values) => {
        let errors = {};

        if (!values.currentPassword) {
            errors.currentPassword = "Password is required";
        }

        if (!values.newPassword) {
            errors.newPassword = "New Password is required";
        } else if (values.newPassword.length < 8) {
            errors.newPassword = "Password must be at least 8 characters";
        } else if (values.newPassword.search(/[a-z]/i) < 0) {
            errors.newPassword = "Password must contain at least one lowercase letter.";
        } else if (values.newPassword.search(/[A-Z]/i) < 0) {
            errors.newPassword = "Password must contain at least one uppercase letter.";
        } else if (values.newPassword.search(/[0-9]/) < 0) {
            errors.newPassword = "Password must contain at least one digit.";
        } else if (values.newPassword.search(/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/i) < 0) {
            errors.newPassword = "Password must contain at least one Special Symbol.";
        }

        if (!values.confirmNewPassword) {
            errors.confirmNewPassword = "Password is required";
        } else if (values.confirmNewPassword.length < 8) {
            errors.confirmNewPassword = "Password must be at least 8 characters";
        } else if (values.confirmNewPassword.search(/[a-z]/i) < 0) {
            errors.confirmNewPassword = "Password must contain at least one lowercase letter.";
        } else if (values.confirmNewPassword.search(/[A-Z]/i) < 0) {
            errors.confirmNewPassword = "Password must contain at least one uppercase letter.";
        } else if (values.confirmNewPassword.search(/[0-9]/) < 0) {
            errors.confirmNewPassword = "Password must contain at least one digit.";
        } else if (values.confirmNewPassword.search(/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/i) < 0) {
            errors.confirmNewPassword = "Password must contain at least one Special Symbol.";
        } else if (values.newPassword !== values.confirmNewPassword) {
            errors.confirmNewPassword = "Password does not match.";
        }

        return errors;
    };

    const changePassword = (e) => {
        console.log(e);

        e['userId'] = localStorage.getItem('PROFILEID');

        UserService.updatePassword(e).then((res) => {
            console.log(res.data.message);
        }).catch((err) => {
            alert('Something went wrong! Check developer console for more info.');
            console.log('Change Password:', err);
        })
    }

    return (
        <div className="change-password">
            <div className="row justify-content-between align-items-center mb-5 change-password-header">
                <div className="col-12 col-md-9 col-xl-7">
                    <h2 className="mb-2">Change your password</h2>
                    <p className="text-muted mb-xl-0">We will email you a confirmation when changing your password, so please expect that email after submitting.</p>
                </div>
                <div className="col-12 col-xl-auto">
                    <button className="btn btn-white pcx-btn-white">Forgot Password?</button>
                </div>
            </div>
            <div className="row change-password-body">
                <div className="col-12 col-md-6">
                    <div className="change-password">
                        <Formik
                            initialValues={initialValues}
                            validate={validate}
                            onSubmit={changePassword}
                        >
                            {(formik) => {
                                const {
                                    values,
                                    handleChange,
                                    handleSubmit,
                                    errors,
                                    touched,
                                    handleBlur,
                                    isValid,
                                    dirty
                                } = formik;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="password"
                                                            className={`form-control ${errors.currentPassword && touched.currentPassword ? "input-error" : null}`}
                                                            value={values.currentPassword}
                                                            id="currentPassword"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="currentPassword">Current Password</label>
                                                        {errors.currentPassword && touched.currentPassword && (
                                                            <span className="error">{errors.currentPassword}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="password"
                                                            className={`form-control ${errors.newPassword && touched.newPassword ? "input-error" : null}`}
                                                            value={values.newPassword}
                                                            id="newPassword"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="newPassword">New Password</label>
                                                        {errors.newPassword && touched.newPassword && (
                                                            <span className="error">{errors.newPassword}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="password"
                                                            className={`form-control ${errors.confirmNewPassword && touched.confirmNewPassword ? "input-error" : null}`}
                                                            value={values.confirmNewPassword}
                                                            id="confirmNewPassword"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="confirmNewPassword">Confirm New Password</label>
                                                        {errors.confirmNewPassword && touched.confirmNewPassword && (
                                                            <span className="error">{errors.confirmNewPassword}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            type="submit"
                                            className={`pcx-btn pcx-btn-primary ${dirty && isValid ? "" : "disabled-btn"}`}
                                            disabled={!(dirty && isValid)}
                                        >
                                            Update Password
                                        </button>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card ms-md-4">
                        <div className="card-body">
                            <p className="mb-2">Password Requirements</p>
                            <p className="small text-muted mb-2">To create a new password, you have to meet all of the following requirements:</p>
                            <ul className="small text-muted ps-4 mb-0">
                                <li>Minimum 8 character</li>
                                <li>One special character</li>
                                <li>One number</li>
                                <li>One captilized letter</li>
                                <li>Can't be the same as a previous password</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword