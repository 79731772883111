import React, { useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from 'react-table';
import { Link, NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';

import { Search, Chat, More, Edit, Transfer } from '../../Icons';

import './ClientsTable.scss';



function GlobalFilter({
    // preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    // const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="search-bar">
            <div className="search-bar-wrapper">
                <Search />
                <input type="search" value={value || ""}
                    placeholder="Search clients..."
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                />
            </div>
        </div>
    )
}


const ClientsTable = ({ data }) => {

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "fullname",
                Cell: AvatarCell,
                imgAccessor: "avatar",
                width: 'auto'
            },
            {
                Header: "Assigned",
                accessor: 'exercise_metrics.assigned',
                Cell: WorkoutCell,
                assignedAccessor: "exercise_metrics",
                width: 'auto',
            },
            {
                Header: "Completed",
                accessor: 'exercise_metrics.completed',
                Cell: WorkoutCell,
                assignedAccessor: "exercise_metrics",
                width: 'auto',
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: StatusCell,
                width: 'auto',
                // Filter: SelectColumnFilter,
                // filter: 'includes'
            },
            {
                Header: "a",
                accessor: "",
                Cell: ActionCell,
                width: 'auto',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable({
        columns,
        data,
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );


    const EmptyContainer = () => {
        return (
            <div className="d-flex justify-content-center align-item-center flex-column empty-container">
                <h2>No Active Clients</h2>
                <p>Add or Invite new clients by clicking "+ Add Client"</p>
            </div>
        )
    }

    const TableContainer = () => {
        return (
            <div className="table-content">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                            <div key={column.id}>
                                <label htmlFor={column.id}>{column.render("Header")}: </label>
                                {column.render("Filter")}
                            </div>
                        ) : null
                    )
                )}
                <table {...getTableProps()} className="table client-table-content align-items-center table-flush">
                    <thead className="head-light">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps({ style: { width: column.width } }))} scope="col">
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted ?
                                                <svg className={`table-sort-icon ${column.isSortedDesc ? 'iconDirectionDesc' : 'iconDirectionAsc'}`} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path></svg>
                                                :
                                                ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className="clients-table">
            <div className="table-wrapper">
                {data.length === 0 ? <EmptyContainer /> : <TableContainer />}
            </div>
        </div>
    )
}



export default ClientsTable


export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            name={id}
            id={id}
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

export function AvatarCell({ value, column, row }) {
    return (
        <div className="d-flex align-items-center avatar-cell">
            <Link to={`/client/${row.original._id}/overview`} className="avatar rounded-circle mr-3">
                {row.original[column.imgAccessor] ? (
                    <img src={row.original[column.imgAccessor]} alt={`avatar-${row.original._id.substr(row.original._id.length - 5)}`} />
                ) : (
                    <Avatar name={value} size="50" round={true} color={'#0c5242'} />
                )
                }
            </Link>
            <Link to={`/client/${row.original._id}/overview`}>
                <div className="media-body">
                    <span className="mb-0 text-sm">{value}</span>
                </div>
            </Link>
        </div>

    );
}


export function WorkoutCell({ value, column, row }) {
    return (
        <div className="workout-cell">
            <p>{value}</p>
        </div>
    );
}


export function StatusCell({ value, column, row }) {

    return (
        <div className="status-cell">
            <span className={`status-badge status-${value}`}>{value}</span>
        </div>
    );
}


export function ActionCell({ value, column, row }) {

    const transferClient = (id) => {
        console.log('Transfer', id);
    }

    return (
        <div className="action-cell">
            <NavLink className="ac-chat" to={`/messages/chat/${row.original._id}`} >
                <Chat />
            </NavLink>
            <div className="dropdown">
                <button className="ac-settings dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <More />
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <Link className="dropdown-item" to={`/client/${row.original._id}/overview`}>
                            <span><Edit /></span>
                            <span>Edit</span>
                        </Link>
                    </li>
                    <li>
                        <span className="dropdown-item disabled" onClick={() => transferClient(row.original._id)}>
                            <span><Transfer /></span>
                            <span>Transfer</span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}