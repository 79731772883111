import { Line } from 'react-chartjs-2';

const BodyFatMeticChart = ({ data }) => {

    const chartData = {
        labels: data.map((entry) => entry.date),
        datasets: [
            {
                label: 'Body Fat',
                data: data.map((entry) => entry.value),
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            },
        ],
    };

    return (
        <div className="metric-chart">
            {data && <Line data={chartData} />}
        </div>
    )
}

export default BodyFatMeticChart