import React, { useEffect, useState } from 'react'
import LibraryService from '../../services/LibraryService';
import LibraryTable from '../../components/Tables/LibraryTable/LibraryTable';
import { LibraryLoader } from '../../components';

const Library = () => {

  const [library, setLibrary] = useState();
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    (async () => {
      await LibraryService.getExercises().then((res) => {
        console.log(res.data.data);
        setLibrary(res.data.data);
      }).catch((err) => {
        console.log(err);
        setErrorState(true);
      })
    })();
  }, [])


  return (
    <div className="library app-main">
      <div className="container">
        <div className="section-header">
          <div className="section-title">
            <h3>Library</h3>
          </div>
        </div>
        <div className="section-content">
          {errorState ?
            <div className="error-state">An error occurred while fetching data. <a href="">Try again.</a></div>
            :
            library ? <LibraryTable data={library} /> : <LibraryLoader />
          }
        </div>
      </div>
    </div>
  )
}

export default Library