import { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { addDays, format, startOfMonth, startOfWeek, endOfMonth, endOfWeek, isSameMonth, isSameDay, addMonths, subMonths, formatISO } from 'date-fns';
import Modal from 'react-modal';

import { WorkoutService } from '../../services';
import { ArrowLeft, ArrowRight } from '../Icons';
import WBLayout from '../WorkoutBuilder/WBLayout';

import './Calendar.scss';
import WorkoutHistory from '../Modals/WorkoutHistory/WorkoutHistory';


const customStyles = {
    content: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%'
    },
};

const completedModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%'
    },
}



const Calendar = () => {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [workouts, setWorkouts] = useState([]);
    const [workout, setWorkout] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);


    function afterOpenModal() {
        console.log('Workout Builder Closed', workout);
    }

    function closeWorkoutBuilder() {
        WorkoutService.getCalendar(localStorage.getItem('PROGRAMME_ID')).then((res) => {
            console.log(res.data.data);
            setWorkouts(res.data.data);
            setIsModalOpen(false);
        }).catch((err) => {
            console.log(err);
        })
    }

    // const params = useParams();
    // const id = params.id;


    Modal.setAppElement('#appMount')

    useEffect(() => {
        (async () => {
            await WorkoutService.getCalendar(localStorage.getItem('PROGRAMME_ID')).then((res) => {
                console.log(res.data.data);
                setWorkouts(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
        })();
    }, [])


    const renderHeader = () => {
        const dateFormat = 'MMMM yyyy';

        return (
            <div className="calendar-header">
                <div className="col col-start" onClick={() => prevMonth()}>
                    <ArrowLeft />
                </div>
                <div className="col col-center">{format(currentMonth, dateFormat)}</div>
                <div className="col col-end" onClick={() => nextMonth()}>
                    <ArrowRight />
                </div>
            </div>
        )

    };

    const renderDays = () => {
        const dateFormat = 'iiii';
        const days = [];
        const startDate = startOfWeek(currentMonth);
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="calendar-days row">{days}</div>
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateFormat = 'd';
        const rows = [];


        let days = [];
        let day = startDate;
        let formattedDate = '';
        let dayName = 'Rest Day';

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                // eslint-disable-next-line
                const obj = (workouts.workouts || []).filter(o => o.date === format(day, 'dd-MM-yyyy'));

                dayName = obj[0] ? obj[0].name : 'Rest Day';

                let dayDetails = obj[0] ? obj[0] : 0;

                days.push(
                    <div
                        className={
                            `col cell ${!isSameMonth(day, monthStart)
                                ? 'disabled'
                                : isSameDay(day, selectedDate) ? 'selected' : ''
                            } ${obj[0] ? `active-day` : `rest-day`}`}
                        key={day}
                        onClick={() => onDateClick(cloneDay, dayDetails)}
                    >
                        <span className="day-name">{dayName}</span>
                        <span className="number">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                    </div>
                );

                day = addDays(day, 1)
            }

            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            )

            days = [];
        }

        return <div className="calendar-body">{rows}</div>
    };

    function onDateClick(day, details) {
        setSelectedDate(day);
        console.log(day);
        console.log(details);

        if (details.status === "completed") {
            console.log('Show completed workout');
            handleCompletedModal(day)
        } else {
            handleEventModal(day);
        }
    }

    function nextMonth() {
        setCurrentMonth(addMonths(currentMonth, 1))
    }

    function prevMonth() {
        setCurrentMonth(subMonths(currentMonth, 1))
    }

    function handleEventModal(day) {
        let currDate = formatISO(selectedDate, { representation: 'date' })
        console.log('Action Here', currDate);
        console.log('Workout', workouts);
        let workout = (workouts.workouts || []).filter(o => o.date === day.toISOString());
        // let pushWorkout = workout ? workout : { date: currDate, status: 'new', id: workouts.pid }
        setWorkout(workout[0])
        setIsModalOpen(true);
    }


    function handleCompletedModal(day) {
        let currDate = formatISO(selectedDate, { representation: 'date' })
        console.log('Action Here', currDate);
        console.log('Workout', workouts);
        let workout = (workouts.workouts || []).filter(o => o.date === day.toISOString());
        // let pushWorkout = workout ? workout : { date: currDate, status: 'new', id: workouts.pid }
        setWorkout(workout[0])
        setIsCompletedModalOpen(true);
    }

    function afterOpenHistoryModal() { }
    function afterCloseHistoryModal() { setIsCompletedModalOpen(false) }

    return (
        <div className="client-calendar">
            {workouts.workouts ?
                <div className="calendar">
                    {renderHeader()}
                    {renderDays()}
                    {renderCells()}
                    <Modal
                        isOpen={isModalOpen}
                        onAfterOpen={afterOpenModal}
                        onAfterClose={closeWorkoutBuilder}
                        style={customStyles}
                        contentLabel="Workout Builder Modal"
                    >
                        <WBLayout
                            day={selectedDate}
                            workout={workout}
                            workoutId={workouts.id}
                            setIsModalOpen={setIsModalOpen}
                        />
                    </Modal>
                    <Modal
                        isOpen={isCompletedModalOpen}
                        style={completedModalStyles}
                        onAfterOpen={afterOpenHistoryModal}
                        onAfterClose={afterCloseHistoryModal}
                        contentLabel="Workout History Modal"
                    >
                        <WorkoutHistory
                            day={selectedDate}
                            workout={workout}
                            workoutId={workouts.id}
                            setIsCompletedModalOpen={setIsCompletedModalOpen}
                        />
                    </Modal>
                </div>
                :
                "Loading..."
            }
        </div>
    )
}

export default Calendar