import React from 'react';
import { Link } from 'react-router-dom';

import { Logo } from '../../Icons';

import './AuthHeader.scss';


const AuthHeader = ({ showURL }) => {

    return (
        <div className="auth-header">
            <div className="pcx-logo">
                <Logo />
            </div>
            {showURL === "signup" ?

                <div className="signup-redirect">
                    <span>Don't have an account? <Link to={'/register'}>Sign Up</Link></span>
                </div>
                :
                <div className="signup-redirect">
                    <span>Already a member? <Link to={'/login'}>Login</Link></span>
                </div>

            }
        </div>
    )
}

export default AuthHeader