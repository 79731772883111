import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { CoachService } from '../../services';

import GeneralProfile from './GeneralProfile';
// import BillingDetails from './BillingDetails';
import NotificationSettings from './NotificationSettings';
import ChangePassword from './ChangePassword';

import './Style.scss';
import { ProfileLoader } from '../../components';



const Account = () => {

  const [profile, setProfile] = useState();

  useEffect(() => {
    (async () => {
      CoachService.getById(localStorage.getItem('uid')).then((res) => {
        setProfile(res.data.data);
      }).catch((err) => {
        console.log(err);
      })
    })();

  }, [])



  return (
    <div className="account app-main">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8">
            <Tabs>
              <TabList>
                <div className="row justify-content-center">
                  <div className="col">
                    <h6 className="header-pretitle">
                      Overview
                    </h6>
                    <h1 className="header-title">
                      Account
                    </h1>
                  </div>
                </div>
                <Tab>General</Tab>
                <Tab>Change Password</Tab>
                {/* <Tab>Billing</Tab> */}
                <Tab>Notifications</Tab>
              </TabList>
              <TabPanel>
                {profile ? <GeneralProfile profile={profile} /> : <ProfileLoader />}
              </TabPanel>
              <TabPanel>
                <ChangePassword data={profile} />
              </TabPanel>
              {/* <TabPanel>
                <BillingDetails billing={profile} />
              </TabPanel> */}
              <TabPanel>
                <NotificationSettings settings={profile} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Account