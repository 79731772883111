
import React, { useState } from 'react';
import { Close, Tick } from '../../Icons';

const AddClient = ({ closeModal, addClient, isLoading }) => {
    const [client, setClient] = useState({
        firstname: "",
        lastname: "",
        mobilenumber: "",
        email: ""
    });


    const submitForm = () => {
        addClient(client)
    }

    const handleChange = (ev) => {
        const value = ev.target.value;
        setClient({
            ...client,
            [ev.target.name]: value
        });
    }


    return (
        <div className="pcx-modal add-client-modal md-modal">
            <div className="pcx-modal-header">
                <h2>Add New Client</h2>
                <Close onClick={closeModal} />
            </div>
            <div className="pcx-modal-content">
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <div className="modal-form-group client-info-section">
                            <label>First Name</label>
                            <input type="text" name="firstname" value={client.firstname} onChange={handleChange} className="pcx-input" />
                        </div>
                    </div>
                    <div className="col-md-6 mb-2">
                        <div className="modal-form-group client-info-section">
                            <label>Last Name</label>
                            <input type="text" name="lastname" value={client.lastname} onChange={handleChange} className="pcx-input" />
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <div className="modal-form-group client-info-section">
                            <label>Mobile Number</label>
                            <input type="tel" name="mobilenumber" value={client.mobilenumber} onChange={handleChange} className="pcx-input" />
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <div className="modal-form-group client-info-section">
                            <label>Email Address</label>
                            <input type="email" name="email" value={client.email} onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pcx-modal-footer">
                <button onClick={() => submitForm()} className={`pcx-btn pcx-btn-skeleton ${isLoading ? 'pcx-btn-loading disabled-btn' : ''}`}>
                    <Tick />
                    <span>Invite</span>
                </button>
            </div>
        </div>
    )
}

export default AddClient