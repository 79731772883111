import { api } from '../middlewares';

const getAll = (id) => {
    return api.get(`/notes/${id}`,);
}

const add = (data) => {
    return api.post(`/note`, data);
}

const edit = (id, data) => {
    return api.put(`/note/${id}`, data);
}

const _delete = (id) => {
    return api.delete(`/note/${id}`);
}


const NoteService = {
    getAll,
    add,
    edit,
    _delete
};

export default NoteService;