import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { signInWithEmailAndPassword } from 'firebase/auth';


// import { UserService } from '../../services';
import AuthHeader from '../../components/Header/AuthHeader/AuthHeader';

import { auth } from '../../firebase';

import './Login.scss';



const Login = () => {


    const navigate = useNavigate();

    const [loginError, setLoginError] = useState();
    const [isLoading, setIsLoding] = useState(false);

    const initialValues = {
        email: "",
        password: "",
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.email) {
            errors.email = "Email is required";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid Email";
        }

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 4) {
            errors.password = "Password too short";
        }

        return errors;
    };


    const authenticate = async (data) => {
        // UserService.authenticate(data).then((res) => {
        //     const { user, token, refreshToken } = res?.data;

        //     console.log(res);



        //     navigate("/clients", { replace: true });

        // }).catch((err) => {
        //     console.log('Error:', err);
        //     if (err.code === "ERR_NETWORK") {
        //         setLoginError('Something went wrong. Please try again.');
        //     } else {
        //         setLoginError(err.response.data.message)
        //     }
        // })

        try {
            setIsLoding(true);
            signInWithEmailAndPassword(auth, data.email, data.password).then((user) => {
                if (user) {
                    const token = user?.user?.stsTokenManager;
                    if (token) {
                        // console.log('token available', token);
                        localStorage.setItem('access_token', token.accessToken);
                        localStorage.setItem('refresh_token', token.refreshToken);
                        localStorage.setItem('uid', `${user.user.uid}`);

                        setTimeout(() => {
                            setIsLoding(false);
                            navigate("/clients", { replace: true });
                        }, 3000)
                    }
                }
            }).catch((err) => {
                console.log(err.code);
                console.log(err.message);

                if (err.code === 'auth/user-not-found') {
                    setLoginError('Invalid credentials')
                }
            })

            // const user = await signInWithEmailAndPassword(auth, data.email, data.password);
            // const token = await user?.user?.stsTokenManager;

            // if (user) {
            //     await localStorage.setItem('access_token', token.accessToken);
            //     await localStorage.setItem('refresh_token', token.refreshToken);
            //     await localStorage.setItem('uid', `${user.user.uid}`);

            //     await navigate("/clients", { replace: true });
            // }
        } catch (error) {
            console.log(error);
            setLoginError(error);
        }
    }



    return (
        <div className="login d-flex align-items-center min-vh-100">
            <AuthHeader showURL={"signup"} />
            <div className="container">
                <div className="justify-content-center row">
                    <div className="my-5 col-xl-4 col-md-5 col-12 auth-bg">
                        <div className="form-wrapper">
                            <Formik
                                initialValues={initialValues}
                                validate={validate}
                                onSubmit={authenticate}
                            >
                                {(formik) => {
                                    const {
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        errors,
                                        touched,
                                        handleBlur,
                                        isValid,
                                        dirty
                                    } = formik;
                                    return (
                                        <div className="container">
                                            <h1 className="mb-4">Sign in</h1>
                                            <form onSubmit={handleSubmit}>
                                                {loginError && <div className="form-error">{loginError}</div>}
                                                <div className="form-group">
                                                    <label htmlFor="email">Email address</label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${errors.email && touched.email ? "input-error" : null}`}
                                                    />
                                                    {errors.email && touched.email && (
                                                        <span className="error">{errors.email}</span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        aria-describedby="passwordHelp"
                                                        placeholder="Enter Password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${errors.password && touched.password ? "input-error" : null}`}
                                                    />
                                                    {errors.password && touched.password && (
                                                        <span className="error">{errors.password}</span>
                                                    )}
                                                </div>
                                                <div className="mb-3 recover-account">
                                                    <p className="text-end"><Link to="/recover-account">Having trouble to sign in?</Link></p>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className={`pcx-btn pcx-btn-primary mt-4 ${isLoading ? 'pcx-btn-loading' : ''} ${dirty && isValid ? "" : "disabled-btn"}`}
                                                    disabled={!(dirty && isValid)}>
                                                    <span>Sign In</span>
                                                </button>
                                            </form>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login