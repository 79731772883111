import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { NotificationService } from '../../services';
import { toast } from 'react-toastify';


const NotificationSettings = () => {

  const uid = localStorage.getItem('uid');

  const [productUpdateAlert, setProductUpdateAlert] = useState(false);
  const [inboxAlert, setInboxAlert] = useState(false);

  useEffect(() => {
    (async () => {
      await NotificationService.getNotificationSettings(uid).then((res) => {
        console.log(res.data.data);
        setProductUpdateAlert(res.data.data.productUpdateAlert);
        setInboxAlert(res.data.data.inboxAlert);
      }).catch((err) => {
        console.log(err);
      })
    })();
  }, [productUpdateAlert, inboxAlert, uid]);



  const updateNotificationAlerts = async (e) => {
    await NotificationService.updateNotificationSettings(uid, e).then((res) => {
      toast.success(res.data.message);
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <div className="notifications-settings">
      <div className="row justify-content-between align-items-center mb-5 change-password-header">
        <div className="col-12 col-md-9 col-xl-7">
          <h2 className="mb-2">Notifications</h2>
          <p className="text-muted mb-xl-0">Customize your preferences for receiving in-app and email alerts</p>
        </div>
      </div>
      <div className="row change-password-body">
        <div className="col-md-12">
          <div className="notification-alerts">
          <Formik
                initialValues={{
                  productUpdateAlert: productUpdateAlert,
                  inboxAlert: inboxAlert,
                }}
                onSubmit={updateNotificationAlerts}
              >
                {(formik) => {
                  const {
                    values,
                    handleChange,
                    handleSubmit,
                    // errors,
                    // touched,
                    // handleBlur,
                    isValid,
                    dirty
                  } = formik;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-2">
                        <div className="col-12">
                          <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Product Release Newsletter</label>
                            <input
                              className="form-check-input"
                              name='productUpdateAlert'
                              defaultValue={values.productUpdateAlert}
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              onChange={handleChange}
                              checked={values.productUpdateAlert}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Alert for new inbox message</label>
                            <input
                              className="form-check-input"
                              name='inboxAlert'
                              defaultValue={values.inboxAlert}
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              onChange={handleChange}
                              checked={values.inboxAlert}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className={`pcx-btn pcx-btn-primary ${dirty && isValid ? "" : "disabled-btn"}`}
                        disabled={!(dirty && isValid)}
                      >
                        Save
                      </button>
                    </form>
                  );
                }}
              </Formik>
          </div>
        </div>
      </div>
    </div>

  )
}

export default NotificationSettings