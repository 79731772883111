import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import './assets/styles/index.scss';

import { store } from './store';
import { router } from './App';
import { AuthProvider } from './context/AuthProvider';
import { SkeletonTheme } from 'react-loading-skeleton';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const root = ReactDOM.createRoot(document.getElementById('appMount'));
root.render(
  <Provider store={store}>
    <AuthProvider>
      <SkeletonTheme baseColor='#152121' highlightColor='#243939'>
        <RouterProvider router={router} />
      </SkeletonTheme>
    </AuthProvider>
  </Provider>
);
