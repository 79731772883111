import { useEffect, useState } from 'react'
import { LibraryService } from '../../services';
import { AddSquare, InfoCircle, Search, Filter } from '../Icons';

const ExercisesList = ({ handleExercisePreview, handleAddExercise, setFilterVisibility }) => {

    const [exercises, setExercises] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [errorState, setErrorState] = useState(false);


    useEffect(() => {
        getExercises();
    }, []);


    const getExercises = async () => {
        LibraryService.getExercises().then((res) => {
            console.log(res.data.data);
            setExercises(res.data.data);
        }).catch((err) => {
            console.log(err);
            setErrorState(true);
        });
    };


    const handleDragStart = (e, exerciseId) => {
        e.dataTransfer.setData('exerciseId', exerciseId.toString())
    }

    function getVimeoVideoId(url) {
        var regex = new RegExp(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
        if (regex.test(url)) {
            return regex.exec(url)[5];
        }
    }

    return (
        <aside className="exercise-library">
            <div className="el-wrapper">
                <div className="el-header">
                    <h3 className="el-section-title">Exercise Library</h3>
                    {/* Search */}
                    <div className="el-search">
                        <Search />
                        <input type="search" name="search_exercises" id="search_exercises" value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder="Search by name..." />
                        <button className="filter-icon filter-toggle" onClick={() => setFilterVisibility(true)}>
                            <Filter />
                        </button>
                    </div>
                </div>
                <div className="exercises">
                    <div className="exercises-wrapper">


                        {errorState ?
                            <div className="error-state">An error occurred while fetching data. <button onClick={() => getExercises()} type="button">Try again</button></div>
                            :

                            exercises && exercises.map((exercise, index) => (
                                <div className="exercise"
                                    onDragStart={(e) => handleDragStart(e, exercise.id)}
                                    key={index}
                                    draggable
                                >
                                    <div className="exercise-wrapper">
                                        {/* Exercise Video */}
                                        <div className="exercise-thumbnail">
                                            <img src={`https://vumbnail.com/${getVimeoVideoId(exercise.video_url)}.jpg`} alt={exercise.name} />
                                        </div>
                                        {/* Exercise Title */}
                                        <div className="exercise-title">
                                            <span>{exercise.name}</span>

                                            <button className="exercise-icon" onClick={() => handleExercisePreview(exercise)}><InfoCircle /></button>
                                            <button className="exercise-icon" onClick={() => handleAddExercise(exercise)}><AddSquare /></button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default ExercisesList