import { useState } from 'react';
import { useFormik } from 'formik';
import { ArrowRightLine, LoaderIcon } from '../../components/Icons';
import UserService from '../../services/UserService';

const CreateAccount = ({ onlyNumbers, nextStep, setUser }) => {

    const [isLoading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            mobilenumber: '',
        },
        onSubmit: function (values) {
            register(values)
        },
        validate: function (values) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
            let errors = {};

            if (!values.firstname) {
                errors.firstname = "First Name is required";
            } else if (values.firstname.length < 3) {
                errors.firstname = "First Name is too short.";
            }

            if (!values.lastname) {
                errors.lastname = "Last Name is required";
            } else if (values.lastname.length < 3) {
                errors.lastname = "Last Name is too short.";
            }

            if (!values.email) {
                errors.email = "Email is required";
            } else if (!regex.test(values.email)) {
                errors.email = "Invalid Email";
            }

            // if (!values.password) {
            //     errors.password = "Password is required";
            // } else if (values.password.length <= 5) {
            //     errors.password = "Password must be at least 6 characters";
            // } else if (values.password.search(/[a-z]/i) < 0) {
            //     errors.password = "Password must contain at least one lowercase letter.";
            // } else if (values.password.search(/[A-Z]/i) < 0) {
            //     errors.password = "Password must contain at least one uppercase letter.";
            // } else if (values.password.search(/[0-9]/) < 0) {
            //     errors.password = "Password must contain at least one digit.";
            // } else if (values.password.search(/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/i) < 0) {
            //     errors.password = "Password must contain at least one Special Symbol.";
            // }

            if (!values.mobilenumber) {
                errors.mobilenumber = "Mobile number is required";
            } else if (values.mobilenumber.length !== 10) {
                errors.mobilenumber = "Enter a valid mobile number.";
            }


            return errors;
        }
    });

    const register = async (data) => {
        setLoading(true);
        UserService.validateAccount(data).then((res) => {
            if (res?.data?.validate) {
                const validate = res?.data?.validate;
                setLoading(false);

                if (validate.email) {
                    formik.setErrors({ email: "Email already taken" })
                }

                if (validate.mobile) {
                    formik.setErrors({ mobilenumber: "Mobile Number already taken" })
                }

                if (!validate.email && !validate.mobile) {
                    nextStep()
                    setUser(data)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div className="mt-5 create-account register">
            <div className="container container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 col-xl-5">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                                <h6 className="mb-4 text-uppercase text-muted">Step 1 of 3</h6>
                                <h1 className="mb-5">Setup Your Account</h1>
                            </div>
                        </div>
                        <div className="register-form">
                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                <div className="row mb-2">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="text"
                                                    className={`form-control ${formik.errors.firstname && formik.touched.firstname ? "input-error" : null}`}
                                                    value={formik.values.firstname}
                                                    id="firstname"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <label htmlFor="firstname">First Name</label>
                                                {formik.errors.firstname && formik.touched.firstname && (
                                                    <span className="error">{formik.errors.firstname}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="text"
                                                    className={`form-control ${formik.errors.lastname && formik.touched.lastname ? "input-error" : null}`}
                                                    value={formik.values.lastname}
                                                    id="lastname"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <label htmlFor="lastname">Last Name</label>
                                                {formik.errors.lastname && formik.touched.lastname && (
                                                    <span className="error">{formik.errors.lastname}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className={`form-control ${formik.errors.email && formik.touched.email ? "input-error" : null}`}
                                                    value={formik.values.email}
                                                    id="email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <label htmlFor="email">Email Address</label>
                                                {formik.errors.email && formik.touched.email && (
                                                    <span className="error">{formik.errors.email}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="password"
                                                    className={`form-control ${formik.errors.password && formik.touched.password ? "input-error" : null}`}
                                                    value={formik.values.password}
                                                    id="password"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <label htmlFor="password">Password</label>
                                                {formik.errors.password && formik.touched.password && (
                                                    <span className="error">{formik.errors.password}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="tel"
                                                    id="mobilenumber"
                                                    maxLength="10"
                                                    aria-describedby="mobilenumberHelp"
                                                    value={formik.values.mobilenumber}
                                                    onKeyPress={onlyNumbers}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className={`form-control ${formik.errors.mobilenumber && formik.touched.mobilenumber ? "input-error" : null}`}
                                                />
                                                <label htmlFor="mobilenumber">Mobile Number</label>
                                                {formik.errors.mobilenumber && formik.touched.mobilenumber && (
                                                    <span className="error">{formik.errors.mobilenumber}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button
                                        type="submit"
                                        className={`pcx-btn pcx-btn-ico  pcx-btn-primary ${formik.dirty && formik.isValid ? "" : "disabled-btn"} ${isLoading ? "pcx-btn-load" : ""}`}
                                        disabled={!(formik.dirty && formik.isValid)}
                                    >
                                        {isLoading ? (
                                            <LoaderIcon />
                                        ) : (
                                            <>
                                                Next: Payment
                                                <ArrowRightLine />
                                            </>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAccount