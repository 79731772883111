import { useState } from 'react'
import { Send } from '../../components/Icons'

const MessageInput = ({ handleSendMsg }) => {

    const [msg, setMsg] = useState('');

    const sendChat = (event) => {
        event.preventDefault();
        if (msg.length > 0) {
            handleSendMsg(msg)
            setMsg('');
        }
    };

    return (
        <div className="message-input">
            <form onSubmit={(event) => sendChat(event)}>
                <div className="message-input-group">
                    <input type="text" placeholder="Type your message here..." onChange={(e) => setMsg(e.target.value)} value={msg} />
                    <button className="pcx-icon">
                        <Send />
                    </button>
                </div>
            </form>
        </div>
    )
}

export default MessageInput