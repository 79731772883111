import React from 'react';

import './Feedback.scss';

const Feedback = () => {


    return (
        <div className="feedback app-main">
            <div className="container">
                <div className="section-header">
                    <div className="section-title">
                        <h3>Feedback</h3>
                    </div>
                </div>
                <div className="section-content">
                    <div className="container">
                        <div className="feedback-content d-flex align-center justify-content-center">
                            <div className="feedback">
                                <div className="feedback-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="mr-2">
                                                <img className="rounded-circle " src="https://prium.github.io/phoenix/v1.11.0/assets/img/team/40x40/57.webp" alt="" />
                                            </div>
                                            <div className="ml-1">
                                                <h4>Pradeep Gokul</h4>
                                                <span className="text-muted">10 min ago</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="feedback-body">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo recusandae nulla rem eos ipsa praesentium esse magnam nemo dolor
                                        sequi fuga quia quaerat cum, obcaecati hic, molestias minima iste voluptates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback