import { api } from '../middlewares';


const getById = (id) => {
    return api.get(`/client/${id}`);
}

const ClientService = {
    getById
}


export default ClientService