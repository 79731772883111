import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const MessageLoader = () => {

    const numberOfRows = 3;
    const renderedRows = [...Array(numberOfRows)].map((e, i) => (
        <div key={i}>
            <div className="row">
                <Skeleton height={'84px'} className='mb-3' />
            </div>
        </div>
    ));

    return (
        <div className="user-list-loader">
            {renderedRows}
        </div>
    )
}

export default MessageLoader