import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import './Style.scss';
import AuthHeader from '../../components/Header/AuthHeader/AuthHeader';

const Recover = () => {


    const initialValues = {
        email: "",
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.email) {
            errors.email = "Email is required";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid Email";
        }

        return errors;
    };

    const recoverAccount = (e) => {
        console.log(e);
    }


    return (
        <div className="recover d-flex align-items-center min-vh-100">
            <AuthHeader showURL={"recover"} />
            <div className="container">
                <div className="justify-content-center row">
                    <div className="my-5 col-xl-4 col-md-5 col-12 auth-bg">
                        <div className="form-wrapper">
                            <Formik
                                initialValues={initialValues}
                                validate={validate}
                                onSubmit={recoverAccount}
                            >
                                {(formik) => {
                                    const {
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        errors,
                                        touched,
                                        handleBlur,
                                        isValid,
                                        dirty
                                    } = formik;
                                    return (
                                        <div className="container">
                                            <h1 className="mb-2">Recover Account</h1>
                                            <p className="mb-4">We'll email the instructions to recover your account.</p>
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="email">Email address</label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${errors.email && touched.email ? "input-error" : null}`}
                                                    />
                                                    {errors.email && touched.email && (
                                                        <span className="error">{errors.email}</span>
                                                    )}
                                                </div>
                                                <button
                                                    type="submit"
                                                    className={`pcx-btn pcx-btn-primary mt-4 ${dirty && isValid ? "" : "disabled-btn"}`}
                                                    disabled={!(dirty && isValid)}>
                                                    Recover
                                                </button>
                                                <div className="mt-4 recover-account">
                                                    <p className="text-center"><Link to="/auth/login">Sign In</Link></p>
                                                </div>
                                            </form>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recover