import React, { Suspense, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Add } from '../../components/Icons';
import ClientsTable from '../../components/Tables/ClientsTable/ClientsTable';
import AddClient from '../../components/Modals/AddClient/AddClient';
import CoachService from '../../services/CoachService';

import { ClientsLoader } from '../../components';

import './Clients.scss';
import { toast } from 'react-toastify';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Clients = () => {

  const [clients, setClients] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoding] = useState(false);


  const openModal = () => {
    setModalOpen(true);
  }
  const afterOpenModal = () => { }
  const closeModal = () => {
    setModalOpen(false);
  }

  const afterModalClosed = async () => {
    await CoachService.getClients(localStorage.getItem('uid')).then((res) => {
      console.log(res);
      setClients(res.data.data);
      closeModal();
    }).catch((err) => {
      console.log(err)
    })
  }

  const addClient = (data) => {

    data['coach'] = localStorage.getItem('uid');
    setIsLoding(true);

    (async () => {
      CoachService.sendInvite(data).then((res) => {
        if (res) {
          afterModalClosed();
          toast.success(res.data.message);
        }
      }).catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      }).finally(() => {
        setIsLoding(false);
      })
    })()
  }


  useEffect(() => {
    (async () => {
      CoachService.getClients(localStorage.getItem('uid')).then((res) => {
        setClients(res.data.data);
        setIsLoaded(true);
      }).catch((err) => {
        console.log(err);
        setIsLoaded(false);
      })
    })();

  }, [])

  return (
    <div className="clients app-main">
      <div className="container">
        {!isLoaded && <ClientsLoader />}
        {isLoaded && <Suspense fallback={<ClientsLoader />}>
          <div className="clients-container">
            <div className="section-header">
              <div className="section-title">
                <h3>All Clients ({clients.length})</h3>
              </div>
              <div className="section-action">
                <button className="pcx-btn pcx-btn-primary pcx-btn-ico" onClick={() => openModal()}>
                  <Add />
                  <span>Add Client</span>
                </button>
              </div>
            </div>
            <div className="section-content">
              <ClientsTable data={clients} />
            </div>
          </div>
        </Suspense>}
      </div>
      <Modal
        isOpen={isModalOpen}
        onAfterOpen={afterOpenModal}
        onAfterClose={afterModalClosed}
        onRequestClose={closeModal}
        beforeClose={closeModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Add Client Modal"
      >
        <AddClient closeModal={closeModal} addClient={addClient} isLoading={isLoading} />
      </Modal>
    </div>
  )
}

export default Clients