import { createContext, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useState(() => {
        onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return 'Loading...';
    }



    return (
        <AuthContext.Provider value={{ user }}>
            {children}
        </AuthContext.Provider>
    )
}


export default AuthContext;