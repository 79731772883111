import { connect } from 'react-redux';
import { LoaderIcon } from '../../Icons';

import './AppLoader.scss';

const AppLoader = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className={`app-loader ${isLoading ? '' : 'hide'}`}>
            <div className="loading">
                <LoaderIcon />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({ isLoading: state.isLoading })

export default connect(mapStateToProps)(AppLoader)