import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowLeft, Spinner } from '../../components/Icons';
import { LibraryService } from '../../services';

import './ExercisePreview.scss';



const ExercisePreview = () => {
    const params = useParams();
    const [exercise, setExercise] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        (async () => {
            await LibraryService.getExerciseById(params.id).then((res) => {
                console.log(res.data.data);
                setExercise(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
        })();
    }, [params])


    return (
        <div className="exercise">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 co-lg-10 col-xl-8">
                        <div className="back-button mb-4">
                            <button className="pcx-back-btn" onClick={() => navigate('/library')}>
                                <ArrowLeft />
                                Back
                            </button>
                        </div>
                        <div className="exercise-wrapper card">
                            <div className="exercise-video-banner">
                                {exercise.video_url === undefined ?
                                    <span>Video Unavailable</span>
                                    :
                                    exercise.video_url ? <ReactPlayer url={exercise.video_url} playing={true} loop={true} muted={true} /> : <Spinner />
                                }
                            </div>
                            <div className="exercise-info-container">
                                <h1>{exercise.name}</h1>
                                <p>{exercise.description && exercise.description[0].children[0].text}</p>
                            </div>
                            <div className="exercise-details">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h4>Body Segment</h4>
                                        {exercise.body_segment && exercise.body_segment.map((segment) => (
                                            <p key={segment.id}>{segment.name}</p>
                                        ))}
                                    </div>
                                    <div className="col-md-4">
                                        <h4>Difficulty</h4>
                                        <p>{exercise.difficulty === "1" ? "Beginner" : exercise.difficulty === "2" ? "Moderate" : "Advanced"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <h4>Programme Types</h4>
                                        {exercise.programme_types && exercise.programme_types.map((programme) => (
                                            <p key={programme.id}>{programme.name}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExercisePreview