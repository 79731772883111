import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';


const initialState = {
    isLoading: false,
};

const combineReducers = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, isLoading: true };

        default:
            return state;
    }
}

export const store = createStore(
    combineReducers,
    applyMiddleware(thunk)
);