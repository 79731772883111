import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const ProfileLoader = () => {

    return (
        <div className="profile-loader">
            <div className="row justify-content-between align-items-center avatar-upload ">
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="avatar">
                                <Skeleton circle={true} width={'100px'} height={'100px'} />
                            </div>
                        </div>
                        <div className="col ms-n2">
                            <Skeleton width={'100px'} height={'20px'} />
                            <Skeleton width={'285px'} height={'17px'} />
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <Skeleton width={'135px'} height={'40px'} />
                </div>
            </div>
            <hr className="my-5"></hr>
            <div className="form-skeleton">
                <div className="row mb-2">
                    <div className="col-12 col-md-6 mb-2">
                        <Skeleton height={'58px'} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Skeleton height={'58px'} />
                    </div>
                    <div className="col-12 mb-2">
                        <Skeleton height={'58px'} />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Skeleton height={'58px'} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Skeleton height={'58px'} />
                    </div>
                </div>
                <Skeleton width={'160px'} height={'40px'} />
            </div>
        </div>
    )
}

export default ProfileLoader