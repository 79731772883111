import { useEffect, useState } from 'react'
import { format } from 'date-fns';

import { NoteService } from '../../services';
import { CloseSquare, Edit, More } from '../../components/Icons'
import { toast } from 'react-toastify';


const Notes = ({ id }) => {

    const [notes, setNotes] = useState([]);
    const [notesInput, setNotesInput] = useState('');
    const [addNewNote, setAddNewNote] = useState(false);
    const [editNote, setEditNote] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        (async () => {
            NoteService.getAll(id).then((res) => {
                setNotes(res.data.data);
                console.log(notes);
            }).catch((err) => {
                console.log(err);
            })
        })();
        // eslint-disable-next-line
    }, [id]);

    const handleNotesInput = (event) => {
        setNotesInput(event.target.value)
    }

    const addNote = async () => {
        const noteObj = {
            content: notesInput,
            client: id,
            coach: localStorage.getItem('uid')
        }

        await NoteService.add(noteObj).then((res) => {
            setNotes([...notes, res.data.data]);
            setNotesInput('');
            setAddNewNote(!addNewNote)
        }).catch((err) => {
            console.log(err);
        })
    }

    // const toggleEditNote = (content) => {
    //     setEditNote(!editNote);
    //     setNotesInput(content);
    // }

    const handleEditNote = (index) => {
        setEditIndex(index);
        setNotesInput(notes[index].content)
    }

    const cancelEditNotes = () => {
        setEditIndex(null);
        setNotesInput('')
    }

    const saveEditedNote = async (id, index) => {
        setIsSaving(true);

        await NoteService.edit(id, { content: notesInput }).then((res) => {
            const updatedNotes = [...notes];
            updatedNotes[index] = { ...updatedNotes[index], content: res.data.data.content };
            setNotes(updatedNotes);
            setEditNote(!editNote);
            setEditIndex(null);
            setNotesInput('')
            toast.success('Notes updated');
            setIsSaving(false);
        }).catch((err) => {
            console.log(err);
            setIsSaving(false);
        })
    }

    const deleteNote = async (id) => {
        await NoteService._delete(id).then((res) => {
            console.log(res.data.data)
            const deletedNotes = notes.filter((note) => note.id !== id);
            setNotes(deletedNotes);
        }).catch((err) => {
            console.log(err);
        })
    }

    return (

        <div className="app-card">
            <div className="app-card-header">
                <h3 className="app-card-title">Notes</h3>
            </div>
            <div className="app-card-content training-notes">
                {notes ?
                    <div className="notes-list mb-2">
                        {notes.map((note, index) => (
                            <div className="note-item" key={index}>
                                {editIndex !== index ?
                                    <div className="note-item-wrapper">
                                        <div className="note-content">
                                            <span className="note">{note.content}</span>
                                            <span className="dropdown">
                                                <button className="notes-option-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <More />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <span className="dropdown-item" onClick={() => handleEditNote(index)}>
                                                            <span><Edit /></span>
                                                            <span>Edit</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="dropdown-item" onClick={() => deleteNote(note.id)}>
                                                            <span><CloseSquare /></span>
                                                            <span>Delete</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </span>
                                        </div>
                                        <p className="note-date">{format(new Date(note.updatedAt), 'LLL do, p')}</p>
                                    </div>
                                    :
                                    <div className="note-content-edit">
                                        <textarea className="edit-note-input" onChange={(e) => setNotesInput(e.target.value)} value={notesInput} rows="3" spellCheck="false"></textarea>
                                        <div className="notes-action">
                                            <button className="pcx-ui-btn cancel-btn" onClick={() => cancelEditNotes(index)}>Cancel</button>
                                            <button className={`pcx-ui-btn success-btn ${isSaving ? 'pcx-loading-btn' : ''}`} onClick={() => saveEditedNote(note.id, index)}>Save</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                    :
                    ''
                }
                {!addNewNote ?
                    <p onClick={() => setAddNewNote(!addNewNote)} className="note-trigger">Add a note</p>
                    :
                    <div className="notes">
                        <textarea className="edit-note-input" onChange={handleNotesInput} rows="3" spellCheck="false"></textarea>
                        <div className="notes-action">
                            <button className="pcx-ui-btn cancel-btn" onClick={() => setAddNewNote(!addNewNote)}>Cancel</button>
                            <button className={`pcx-ui-btn success-btn ${isSaving ? 'pcx-loading-btn' : ''}`} onClick={() => addNote()}>Save</button>
                        </div>
                    </div>
                }
            </div>
        </div>


    )
}

export default Notes