import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import './LibraryLoader.scss';


const LibraryLoader = () => {

    const numberOfRows = 3;
    const renderedRows = [...Array(numberOfRows)].map((e, i) => (
        <div key={i}>
            <div className="row">
                <Skeleton height={'90px'} />
            </div>
        </div>
    ));

    return (
        <div className="client-table-loader">
            <div className="section-content">
                {renderedRows}
            </div>
        </div>
    )
}

export default LibraryLoader