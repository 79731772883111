import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useParams, NavLink, Outlet } from 'react-router-dom';

import './ClientProfile.scss';
import { ClientService } from '../../services';


const ClientProfile = () => {

    const params = useParams();
    const [client, setClient] = useState();
    const id = params.id;

    useEffect(() => {
        (async () => {
            ClientService.getById(id).then((res) => {
                console.log(res.data.data);
                setClient(res.data.data);
                localStorage.setItem('PROGRAMME_ID', res.data.data.wid);
            }).catch((err) => {
                console.log(err);
            })
        })();
    }, [id])

    return (
        <div className="app-main client-profile">
            <div className="client-profile-header">
                <div className="client-profile-header-left">
                    <div className="d-flex align-items-center">
                        <div className="avatar">
                            <NavLink to="overview">
                                {client?.avatar ? (
                                    <img src={client.avatar} alt={client?.fullname} />
                                ) : (
                                    <Avatar name={client?.fullname} size="52" round={true} color={'#0c5242'} />
                                )}
                            </NavLink>
                        </div>
                        <div className="client-info">
                            <h3 className="client-name">{client?.fullname}</h3>
                            <div className="client-menu">
                                <NavLink to="overview" className="client-menu-item">Overview</NavLink>
                                <NavLink to={`calendar`} className="client-menu-item">Calendar</NavLink>
                                <NavLink to="metrics" className="client-menu-item">Metrics</NavLink>
                                {/* <NavLink to="settings" className="client-menu-item">Settings</NavLink> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="client-profile-content">
                <div className="container">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ClientProfile