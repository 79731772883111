import React, { useState } from 'react';

import './ExerciseFilterDropdown.scss';

const ExerciseFilterDropdown = ({ isFilterVisible, setFilterVisibility }) => {

    const ProgrammeTypes = ["Strength & Conditioning", "Bodybuilding/Hypertrophy", "Weightloss/Fatloss", "Calisthenics/Bodyweight", "Kid's Fitness", "Pre-Natal", "Post-Natal", "Senior's Fitness", "Flexibility", "Yoga", "Injury Reduction", "Injury Rehabilitation", "AP - Power", "AP - Acceleration", "Sport Performance"];
    const BodySegments = ["Abs", "Back", "Butt/Hips", "Legs-Calves", "Thighs"];
    const Difficulty = ["Beginner", "Moderate", "Advanced"];
    // const Equipments = ["Dumbbells", "Barbell", "Bench", "Resistance Bands/Cables", "BOSU Trainer", "TRX", "Raised Platform/Box", "Stability Ball", "Medicine Ball", "No Equipment", "Cones", "Weight Machines / Selectorized", "Pull up bar", "Ladder", "Hurdles", "Kettlebells", "Heavy Ropes"];

    const [checkboxes, setCheckboxes] = useState();

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        console.log(name, checked)
        setCheckboxes({
            ...checkboxes,
            [name]: checked,
        });
    };

    const getCheckedValues = () => {
        const checkedValues = Object.keys(checkboxes).filter(
            (checkboxName) => checkboxes[checkboxName]
        );
        setFilterVisibility(false);
        setCheckboxes([]);

        return checkedValues;
    };

    const clearAllFilters = () => {
        setCheckboxes([]);
    };

    // const clearAllCheckboxes = () => {
    //     const clearedCheckboxes = {};
    //     for (const key in checkboxes) {
    //         clearedCheckboxes[key] = false;
    //     }
    //     setCheckboxes([]);
    // };

    return (
        <div className={`filter-menu ${isFilterVisible ? 'open' : ''}`}>
            <div className="filter-menu-header d-flex align-items-center justify-content-between">
                <h4>Exercise Filter</h4>
                {/* <button
                    className='pcx-ui-btn cancel-btn clear-all-btn'
                    onClick={() => clearAllCheckboxes()}
                >Clear All</button> */}
            </div>
            <div className="filter-menu-content">
                <div className="filter-section">
                    <div className="filter-section-title">Category</div>
                    <div className="filter-section-options">
                        {ProgrammeTypes.map((programme, index) => (
                            <div className="form-check" key={index}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={programme}
                                    id="category"
                                    name={programme}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="category">
                                    {programme}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="filter-section">
                    <div className="filter-section-title">Difficulty</div>
                    <div className="filter-section-options">
                        {Difficulty.map((difficulty, index) => (
                            <div className="form-check" key={index}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={difficulty}
                                    id="difficulty"
                                    name={difficulty}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="difficulty">
                                    {difficulty}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="filter-section">
                    <div className="filter-section-title">Primary Focus</div>
                    <div className="filter-section-options">
                        {BodySegments.map((body, index) => (
                            <div className="form-check" key={index}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={body}
                                    id="primaryFocus"
                                    name={body}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="primaryFocus">
                                    {body}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="filter-menu-footer d-flex align-items-center justify-content-end">
                <button className="pcx-ui-btn cancel-btn" onClick={() => clearAllFilters()}>Clear</button>
                <button className="pcx-ui-btn success-btn" onClick={() => console.log(getCheckedValues())}>Update</button>
            </div>
        </div>
    )
}

export default ExerciseFilterDropdown