import React from 'react'

const ClientSettings = () => {
  return (
    <div className="client-settings">
      <div className="row justify-content-center">
        <div className="col">
          <h1 className="header-title">
            Settings
          </h1>
        </div>
      </div>
    </div>
  )
}

export default ClientSettings