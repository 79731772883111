import { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { Line } from 'react-chartjs-2';
import { Add, BarChart } from '../../components/Icons';
import { useParams } from 'react-router-dom';

import './ClientMetrics.scss';
import { MetricService } from '../../services';
import { toast } from 'react-toastify';

const ClientMetrics = () => {
  const params = useParams();
  const id = params.id;

  const [data, setData] = useState([]);

  const [addNewRow, setAddNewRow] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // Store the index of the row being edited
  const [newRow, setNewRow] = useState({ date: '', time: '', value: 0 });

  useEffect(() => {
    (async () => {
      await MetricService.getAll(id, 'weight').then((res) => {
        console.log(res.data.data)
        setData(res.data.data)
      }).catch((err) => {
        console.log(err);
      })
    })();
  }, [id]);


  const addRow = () => {

    newRow['metric'] = "weight";
    newRow['client'] = id
    newRow['unit'] = "kg"

    MetricService.add(newRow).then((res) => {
      setData((prevData) => [
        ...prevData,
        { ...res.data.data },
      ]);
      setAddNewRow(!addNewRow);
      setNewRow({ date: '', time: '', value: 0 })
    }).catch((err) => {
      console.log(err);
    })
  };


  const editRow = (row) => {
    setEditIndex(row.index);
  };

  const saveRow = (row) => {
    MetricService.edit(row.original.id, row.original).then((res) => {
      const updatedData = [...data];
      updatedData[row.index] = res.data.data;
      setData(updatedData);
      setEditIndex(null);
      toast.success('Updated')
    }).catch((err) => {
      console.log(err);
    })
  };

  const cancelEdit = () => {
    setEditIndex(null);
  };

  const deleteRow = (row) => {

    MetricService._delete(row.original._id).then((res) => {
      setData((prevData) => prevData.filter((_, index) => index !== row.index));
      toast.info(res.data.message);
    }).catch((err) => {
      console.log(err);
    })

  };

  const handleInputChange = (row, column, value) => {
    const updatedRow = { ...row.original };
    updatedRow[column] = value;
    row.original = updatedRow;
  };

  // Define the columns for the table
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => (
          row.index === editIndex ? (
            <input
              type="date"
              defaultValue={row.original.date}
              onChange={(e) => handleInputChange(row, 'date', e.target.value)}
            />
          ) : (
            row.original.date
          )
        ),
      },
      {
        Header: 'Time',
        accessor: 'time',
        Cell: ({ row }) => (
          row.index === editIndex ? (
            <input
              type="time"
              defaultValue={row.original.time}
              onChange={(e) => handleInputChange(row, 'time', e.target.value)}
            />
          ) : (
            row.original.time
          )
        ),
      },
      {
        Header: 'Value',
        accessor: 'value',
        Cell: ({ row }) => (
          row.index === editIndex ? (
            <input
              type="number"
              defaultValue={row.original.value}
              onChange={(e) => handleInputChange(row, 'value', e.target.value)}
            />
          ) : (
            row.values.value
          )
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => {
          if (row.index === editIndex) {
            return (
              <div>
                <button className="pcx-ui-btn success-btn" onClick={() => saveRow(row)}>Save</button>
                <button className="pcx-ui-btn cancel-btn" onClick={() => cancelEdit(row)}>Cancel</button>
              </div>
            );
          } else {
            return (
              <div>
                <button className="pcx-ui-btn cancel-btn" onClick={() => editRow(row)}>Edit</button>
                <button className="pcx-ui-btn cancel-btn" onClick={() => deleteRow(row)}>Delete</button>
              </div>
            );
          }
        },
      },
    ],
    // eslint-disable-next-line
    [editIndex]
  );

  // Initialize the React Table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });



  // const handleNewInputRow = () => {

  // }

  // Chart data

  function getGradient(chart) {

    const { ctx } = chart;

    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, "rgba(57, 168, 137, 0.5)");
    gradient.addColorStop(0.5, "rgba(57, 168, 137, 0.25)");
    gradient.addColorStop(1, "rgba(57, 168, 137, 0)");
    return gradient;
  }

  const chartData = () => {
    return {
      labels: data.map((row) => row.date),
      datasets: [
        {
          label: 'Weight',
          data: data.map((row) => row.value),
          borderColor: 'rgba(57, 168, 137, 1)',
          fill: true,
          pointBackgroundColor: 'white',
          borderWidth: 3,
          tension: 0.4,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { chartArea } = chart;

            if (!chartArea) {
              return null
            }

            if (context.datasetIndex === 0) {
              return getGradient(chart);
            } else {
              return 'black'
            }
          },
        },
      ],
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      easing: 'easeInOutQuad',
      duration: 520
    },
    scales: {
      x: {
        // display: false,
        ticks: {
          display: true
        },
        gridLines: {
          color: 'red',
          lineWidth: 1
        },
      },
      y: {
        ticks: {
          display: true
        },
        gridLines: {
          color: 'red',
          lineWidth: 1
        },
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }



  const EmptyChartContainer = () => {
    return (
      <div className="empty-chart-container">
        <div className="empty-chart-content">
          <div className="empty-chart-data">
            <BarChart />
            <p>No Data</p>
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className="client-metrics">
      <div className="row justify-content-center mb-5">
        <div className="col">
          <h1 className="header-title">
            {/* Body Metrics */}
          </h1>
        </div>
      </div>
      <div className="client-metrics-inner">
        <div className="metric-tab weight">
          <h3 className="table-header">Weight</h3>

          {/* chart */}
          <div className="metric-chart">
            {data.length > 0 ? <Line data={chartData()} options={chartOptions} /> : <EmptyChartContainer />}
          </div>
          {/* table */}
          <h3 className="table-header">Progress</h3>

          <div className="metric-table">
            {addNewRow ?
              <div className="add-new-row">
                <div className="form-group">
                  <input type="date" placeholder="DD/MM/YYYY" name="date" id="date" value={newRow.date} className="form-control" onChange={(e) => setNewRow({ ...newRow, date: e.target.value })} />
                </div>
                <div className="form-group">
                  <input type="time" placeholder="HH:MM" name="time" id="time" value={newRow.time} className="form-control" onChange={(e) => setNewRow({ ...newRow, time: e.target.value })} />
                </div>
                <div className="form-group">
                  <input type="number" name="value" id="value" value={newRow.value} className="form-control" onChange={(e) => setNewRow({ ...newRow, value: e.target.value })} />
                </div>
                <div className="form-group">
                  <button className="pcx-ui-btn cancel-btn" onClick={() => setAddNewRow(!addNewRow)}>
                    <span>Cancel</span>
                  </button>
                  <button className="pcx-ui-btn success-btn" onClick={addRow}>
                    <span>Add</span>
                  </button>
                </div>
              </div>
              :
              <div className="add-new-row-btn">
                <button className="pcx-btn pcx-btn-primary pcx-btn-ico" onClick={() => setAddNewRow(!addNewRow)}>
                  <Add />
                  <span>Add Row</span>
                </button>
              </div>
            }


            <table className="table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientMetrics


