import { api } from '../middlewares';

const getWorkout = (id) => {
    return api.get(`/workout/${id}`);
}

const getCalendar = (id) => {
    return api.get(`programme/calendar/${id}`);
}

const addWorkout = (data) => {
    return api.post(`/programme/add`, data);
}

const updateWorkout = (id, data) => {
    return api.put(`/programme/update/${id}`, data);
}

const WorkoutService = {
    getWorkout,
    getCalendar,
    addWorkout,
    updateWorkout
};

export default WorkoutService;