import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';

import { CoachService } from '../../services';
import useMutation from '../../hooks/useMutation';
import { format } from 'date-fns';

const GeneralProfile = ({ profile }) => {

    // const [fileName, setFileName] = useState('');
    const [fileError, setFileError] = useState(false);

    const {
        mutate: uploadImage,
        isLoading: uploading
    } = useMutation({ url: '/user/upload-avatar' })

    const dateOfBirth = profile.dateofbirth ? format(new Date(profile.dateofbirth), 'yyyy-MM-dd') : '';

    const initialValues = {
        firstname: profile.firstname,
        lastname: profile.lastname,
        email: profile.email,
        mobilenumber: String(profile.mobilenumber),
        dateofbirth: dateOfBirth
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.firstname) {
            errors.firstname = "First Name is required";
        } else if (values.firstname.length < 3) {
            errors.firstname = "First Name is too short.";
        }

        if (!values.lastname) {
            errors.lastname = "Last Name is required";
        } else if (values.lastname.length < 3) {
            errors.lastname = "Last Name is too short.";
        }

        if (!values.email) {
            errors.email = "Email is required";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid Email";
        }

        if (!values.mobilenumber) {
            errors.mobilenumber = "Mobile number is required";
        } else if (values.mobilenumber.length !== 10) {
            errors.mobilenumber = "Enter a valid mobile number.";
        }

        if (!values.dateofbirth) {
            errors.dateofbirth = "Date of Birth is required";
        }

        return errors;
    };

    const validateFileType = ['image/jpg', 'image/jpeg', 'image/png'];


    // Handle Profile Image Upload
    const handleFile = async (file) => {

        if (validateFileType.find(type => type === file.type)) {
            // setFileName(file.name);
            setFileError(false);

            const formData = new FormData();
            formData.append('avatar', file);
            formData.append('id', localStorage.getItem('uid'));
            await uploadImage(formData);
            await toast.success('Uploaded successfully')
        } else {
            toast.error('Invalid File Type. Your image must be in PNG or JPG format')
            setFileError(true);
        }
    };

    const hiddenFileInput = useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    };


    // Handle Profile Info Update
    const updateProfile = (data) => {

        CoachService.updateProfile(localStorage.getItem('uid'), data).then((res) => {
            toast.success('Updated');
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong. Try again.')
        })
    };

    return (
        <div className="general-profile">
            {fileError && <div className="form-group text-center"><span className="error">File must be PNG or JPG</span></div>}
            <div className="row justify-content-between align-items-center avatar-upload ">
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="avatar" onClick={() => handleClick()}>
                                {profile.avatar ? (
                                    <Avatar src={profile.avatar} size="100" round={true} color={'#0c5242'} />
                                ) : (
                                    <Avatar name={profile.firstname + ' ' + profile.lastname} size="100" round={true} color={'#0c5242'} />
                                )}
                            </div>
                        </div>
                        <div className="col ms-n2">
                            <h4>Profile Picture</h4>
                            <small className="text-muted">PNG or JPG no bigger than 1000px wide and tall.</small>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <button className={`pcx-btn pcx-btn-primary ${uploading ? 'pcx-btn-loading' : ''}`} onClick={() => handleClick()}>
                        <span>Upload</span>
                    </button>
                    <input type="file" onChange={handleChange} ref={hiddenFileInput} name="avatar" style={{ display: 'none' }} accept="image/*" />
                </div>
            </div>
            <hr className="my-5" />
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={updateProfile}
            >
                {(formik) => {
                    const {
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        handleBlur,
                        isValid,
                        dirty
                    } = formik;
                    // console.log(errors)
                    // console.log(values)
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-2">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.firstname && touched.firstname ? "input-error" : null}`}
                                                value={values.firstname}
                                                id="firstname"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder=""
                                            />
                                            <label htmlFor="firstname">First Name</label>
                                            {errors.firstname && touched.firstname && (
                                                <span className="error">{errors.firstname}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.lastname && touched.lastname ? "input-error" : null}`}
                                                value={values.lastname}
                                                id="lastname"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder=""
                                            />
                                            <label htmlFor="lastname">Last Name</label>
                                            {errors.lastname && touched.lastname && (
                                                <span className="error">{errors.lastname}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="email"
                                                className={`form-control ${errors.email && touched.email ? "input-error" : null}`}
                                                value={values.email}
                                                id="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder=""
                                                disabled
                                            />
                                            <label htmlFor="email">Email Address</label>
                                            {errors.email && touched.email && (
                                                <span className="error">{errors.email}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="tel"
                                                maxLength="10"
                                                className={`form-control ${errors.mobilenumber && touched.mobilenumber ? "input-error" : null}`}
                                                value={values.mobilenumber}
                                                id="mobilenumber"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder=""
                                            />
                                            <label htmlFor="mobilenumber">Mobile Number</label>
                                            {errors.mobilenumber && touched.mobilenumber && (
                                                <span className="error">{errors.mobilenumber}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    {/* <CustomDatePicker name="dateofbirth" /> */}
                                    <div className="form-group">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="date"
                                                className={`form-control ${errors.dateofbirth && touched.dateofbirth ? "input-error" : null}`}
                                                defaultValue={values.dateofbirth || 'YYYY-MMM-DD'}
                                                id="dateofbirth"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="DD-MMM-YYYY"
                                            />
                                            <label htmlFor="dateofbirth">Date of Birth</label>
                                            {errors.dateofbirth && touched.dateofbirth && (
                                                <span className="error">{errors.dateofbirth}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className={`pcx-btn pcx-btn-primary ${dirty && isValid ? "" : "disabled-btn"}`}
                                disabled={!(dirty && isValid)}
                            >
                                Update Profile
                            </button>
                        </form>
                    );
                }}
            </Formik>
            {/* <hr className="my-5" /> */}
            {/* <div className="row justify-content-between align-items-center">
                <div className="col-12 col-md-6">
                    <h4 className="delete-title">Delete your account</h4>
                    <p className="small delete-pretitle text-muted mb-md-0">Please note, deleting your account is a permanent action and will not be recoverable once completed.</p>
                </div>
                <div className="col-auto">
                    <button className="pcx-btn pcx-btn-danger" onClick={openModal}>Delete</button>
                </div>
            </div> */}
        </div>
    )
}

export default GeneralProfile