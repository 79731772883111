import React from 'react'

const MessageWelcome = () => {
  return (
    <div className="message-welcome">
      <div className="message-welcome-wrapper">
        <div className="message-welcome-content">
          <h2>Welcome!</h2>
          <p>Now send and recieve messages to your clients and view their activites.</p>
        </div>
      </div>
    </div>
  )
}

export default MessageWelcome