import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoaderIcon } from '../../components/Icons';

const Finished = () => {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/login');
        }, 3000);
    }, [navigate])

    return (
        <div className="registeration-finished">
            <div className="container container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 text-center">
                        <h6 className="mb-4 text-uppercase text-muted">Done</h6>
                        <h1 className="mb-5">Finished</h1>
                    </div>
                    <div className="row justify-content-center mb-2">
                        <div className="col-12 col-md-6">
                            <div className="registration-completed-message d-flex flex-column justify-content-center align-items-center">
                                {/* <button className="pcx-btn pcx-btn-primary">Login</button> */}
                                <h4>Your account has been created successfully.</h4>
                                <p>You will be redirected shortly...</p>
                                <div className="process_loader">
                                    <LoaderIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Finished