import React, { useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from 'react-table';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { Search } from '../../Icons';

import './LibraryTable.scss';

function GlobalFilter({
    // preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    // const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="search-bar">
            <div className="search-bar-wrapper">
                <Search />
                <input type="search" value={value || ""}
                    placeholder="Search exercises..."
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                />
            </div>
        </div>
    )
}

const LibraryTable = ({ data }) => {

    console.log(data);

    const columns = useMemo(
        () => [
            {
                Header: "Exercise",
                accessor: "name",
                Cell: ThumbnailCell,
                width: 'auto'
            },
            {
                Header: "Programme Types",
                accessor: "programme_types[0].name",
                width: 'auto'
            },
            {
                Header: "Updated On",
                accessor: "updatedAt",
                Cell: DateCell,
                width: 'auto'
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,

        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        // setPageSize,
        // state: { pageIndex, pageSize },

    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 1 },
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const EmptyContainer = () => {
        return (
            <div className="d-flex justify-content-center align-item-center flex-column empty-container">
                <h2>No exercises found</h2>
                <p>Please contact the admin for assistance.</p>
            </div>
        )
    }

    const TableContainer = () => {
        return (
            <div className="table-content">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                            <div key={column.id}>
                                <label htmlFor={column.id}>{column.render("Header")}: </label>
                                {column.render("Filter")}
                            </div>
                        ) : null
                    )
                )}
                <table {...getTableProps()} className="table library-table-content align-items-center table-flush">
                    <thead className="head-dark">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps({ style: { width: column.width } }))} scope="col">
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted ?
                                                <svg className={`table-sort-icon ${column.isSortedDesc ? 'iconDirectionDesc' : 'iconDirectionAsc'}`} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path></svg>
                                                :
                                                ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }




    return (
        <div className="library-table">
            <div className="table-wrapper">
                {data.length === 0 ? <EmptyContainer /> : <TableContainer />}
            </div>
        </div>
    )
}

export default LibraryTable


export function ThumbnailCell({ value, column, row }) {


    let videoURL = row.original.video_url;
    let videoId = videoURL ? row.original.video_url.match(/\d/g) : null;
    videoId = videoId ? videoId.join("") : '';

    return (
        <div className="library-item">
            <Link to={`/library/exercise/${row.original.id}`} className="d-flex align-center">
                <div className="video-thumbnail">
                    <img src={`https://vumbnail.com/${videoId}.jpg`} alt={row.original.name} />
                </div>
                <div className="library-info">
                    <h5>{value}</h5>
                    <p>{row.original.description[0].children[0].text}</p>
                </div>
            </Link>
        </div>

    );
}

export function DateCell({ value, column, row }) {
    return (
        <div className="updated-on-cell">
            <p>{format(new Date(value), 'dd/MM/yyyy')}</p>
        </div>
    )
}