import React from 'react';

import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

// Layout's
import Root from './containers/Layout/Root';
import AuthLayout from './containers/Layout/AuthLayout';
import AppLayout from './containers/Layout/AppLayout';
import Error from './containers/Error/Error';


// Auth's
import Login from './containers/Login/Login';
import Register from './containers/Register/Register';
import Recover from './containers/Recover/Recover';


// App's
// import Dashboard from './containers/Dashboard/Dashboard';
import Clients from './containers/Clients/Clients';
import ClientProfile from './containers/ClientProfile/ClientProfile';
import ClientOverview from './containers/ClientProfile/ClientOverview';
import ClientTraining from './containers/ClientProfile/ClientTraining';
import ClientMetrics from './containers/ClientProfile/ClientMetrics';
import ClientSettings from './containers/ClientProfile/ClientSettings';

import Messages from './containers/Messages/Messages';
import MessageWelcome from './containers/Messages/MessageWelcome';
import Conversations from './containers/Messages/Conversations';

import Library from './containers/Library/Library';
import Account from './containers/Account/Account';
import Feedback from './containers/Feedback/Feedback';
import ExercisePreview from './containers/ExercisePreview/ExercisePreview';
import DownloadApp from './containers/DownloadApp/DownloadApp';
import Privacy from './containers/Privacy/Privacy';


export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/*' element={<Root />}>
      <Route element={<AuthLayout />} >
        <Route index path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='recover-account' element={<Recover />} />
        <Route path='download-app' element={<DownloadApp />} />
        <Route path='privacy' element={<Privacy />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Route>

      <Route element={<AppLayout />}>
        <Route path='clients' element={<Clients />}>
          <Route path=':id' element={<ClientProfile />} />
        </Route>
        <Route path='client/:id' element={<ClientProfile />}>
          <Route index path='overview' element={<ClientOverview />} />
          <Route path='calendar' element={<ClientTraining />} />
          <Route path='metrics' element={<ClientMetrics />} />
          <Route path='settings' element={<ClientSettings />} />
        </Route>
        <Route path='messages' element={<Messages />}>
          <Route index path='' element={<MessageWelcome />} />
          <Route path='chat/:id' element={<Conversations />} />
        </Route>
        <Route path='library' element={<Library />} />
        <Route path='library/exercise/:id' element={<ExercisePreview />} />
        <Route path='account' element={<Account />} />
        <Route path='feedback' element={<Feedback />} />
        <Route path="*" element={<Error />} />
      </Route>

    </Route>
  )
)