import React from 'react'
import Calendar from '../../components/Calendar/Calendar'

const ClientTraining = () => {

  

  return (
    <div className="client-training">
      <div className="row justify-content-center">
        <div className="col">
          <h1 className="header-title">
            Calendar
          </h1>
        </div>
      </div>
      <Calendar />
    </div>
  )
}

export default ClientTraining