import { api } from '../middlewares';


const authenticate = (user) => {
    return api.post('/user/authenticate', user);
}


const getById = (id) => {
    return api.get(`/user/${id}`);
}

const validateAccount = (data) => {
    return api.post(`/user/validate`, data);
}


const register = (data) => {
    return api.post(`/user/create`, data);
}


const UserService = {
    authenticate,
    getById,
    validateAccount,
    register
}

export default UserService